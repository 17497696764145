const getCommentsQuery = (entityId: number) => {
  return `
  query{
    readComments(filter:{entityID:{eq:${entityId}}}, sort:{lastEdited:DESC}){
      id,
      lastEdited,
      created, 
      authorName,
      authorEmail,
      fieldId,
      content,
      resolved, 
      subject, 
      quote,
      subcomments{
        nodes{
          id,
          lastEdited,
          created, 
          authorName,
          authorEmail,
          content,
          commentID,
        }
      }
    }
  }
  `;
};

export default getCommentsQuery;
