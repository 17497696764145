import { PhraseMatch } from "kb-lexicon/dist/src/types/Interfaces";
import { useState, useEffect } from "react";
import { CurrentSelection } from "../types";

interface Props {
  activeEditor: number | null;
  setActivePhrase: (phraseMatch: PhraseMatch | null) => void;
  lexiconHits: { [key: string]: PhraseMatch[] }[];
  comparisonHits: { [key: string]: PhraseMatch[] }[];
}

const useCurrentSelection = ({
  activeEditor,
  setActivePhrase,
  lexiconHits,
  comparisonHits,
}: Props) => {
  const [currentSelection, setCurrentSelection] = useState<CurrentSelection | null>();

  // Updates active word when seleciton changes
  useEffect(() => {
    if (currentSelection && activeEditor != null && currentSelection.position !== 0) {
      const position = currentSelection.position;
      let foundPhrase: PhraseMatch | null = null;

      const blocks =
        currentSelection.panel === "main"
          ? lexiconHits[activeEditor]
          : comparisonHits[activeEditor];
      if (blocks && Object.prototype.hasOwnProperty.call(blocks, currentSelection.key)) {
        loop1: for (let hit of blocks[currentSelection.key])
          for (let match of hit.matches) {
            if (
              position >= match.position &&
              position <= match.position + match.foundWord.length - 1
            ) {
              foundPhrase = hit;
              break loop1;
            }
          }
      }

      if (foundPhrase) {
        setActivePhrase(foundPhrase);
      } else {
        setActivePhrase(null);
      }
    }
  }, [currentSelection, lexiconHits, activeEditor, setActivePhrase, comparisonHits]);

  return { currentSelection, setCurrentSelection };
};

export default useCurrentSelection;
