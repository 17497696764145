import { Editor, EditorState, SelectionState } from "draft-js";
import React, { useEffect, useRef, useState } from "react";
import { SyntheticEvent } from "react-draft-wysiwyg";
import { DiffMethod, StringDiff } from "react-string-diff";
import getColumnWidths from "../helpers/getColumnWidths";
import { Editor as EditorType, Panel } from "../types";
import { AppStateAction, useAppState } from "./contextProviders/AppStateProvider";
import { useConfig } from "./contextProviders/ConfigProvider";
import { useEntity } from "./contextProviders/EntityProvider";
import { useGlobalState } from "./contextProviders/GlobalAppStateProvider";

interface Props {
  activeEditor: number | null;
  activePanel: Panel;
  editor: EditorType;
  comparisonLocale: string;
  currentLocale: string;
  errorMessage?: string;
  loading?: boolean;
  handleEditorFocus: (state: null | number, panel: Panel) => void;
  handleChange: (e: EditorState, id: number, panel: Panel, locale: string) => void;
  setQuote: (input: string) => void;
  onTranslate: (editor: EditorType) => void;
  onReTranslate: (editor: EditorType) => void;
}

const TextEditor: React.FC<Props> = (props) => {
  const {
    activeEditor,
    activePanel,
    children,
    comparisonLocale,
    currentLocale,
    errorMessage,
    editor,
    loading = false,
    handleChange,
    handleEditorFocus,
    setQuote,
    onTranslate,
    onReTranslate
  } = props;

  const [selection, setSelection] = useState("");
  const config = useConfig();
  const { versions } = useEntity();
  const AppState = useAppState();
  const GlobalState = useGlobalState();
  const [mainWidth, comparisonWidth] = getColumnWidths(currentLocale, comparisonLocale, config);

  const editorRef = useRef<Editor>(null);

  //Setting selection if user selects text in the editor
  useEffect(() => {
    const selectionState = editor.editorStates[currentLocale].getSelection();
    const isBackward = selectionState.getIsBackward();
    const anchorKey = selectionState.getAnchorKey();
    const focusKey = selectionState.getFocusKey();
    const anchorOffset = selectionState.getAnchorOffset();
    const focusOffset = selectionState.getFocusOffset();
    const content = editor.editorStates[currentLocale].getCurrentContent();
    const startKey = isBackward ? focusKey : anchorKey;
    const endKey = isBackward ? anchorKey : focusKey;
    const contentBlocks = content.getBlocksAsArray();
    const startIndex = contentBlocks.findIndex((block) => block.getKey() === startKey);
    let startOffset = 0;
    for (let index = 0; index < startIndex; index++) {
      startOffset += contentBlocks[index].getLength() + 1;
    }
    const start = startOffset + (isBackward ? focusOffset : anchorOffset);
    const endIndex =
      anchorKey === focusKey
        ? startIndex
        : contentBlocks.findIndex((block) => block.getKey() === endKey);
    let endOffset = startOffset;
    for (let index = startIndex; index < endIndex; index++) {
      endOffset += contentBlocks[index].getLength() + 1;
    }
    let end = endOffset + (isBackward ? anchorOffset : focusOffset);
    let selectionRange = [start, end];
    const text = editor.editorStates[currentLocale].getCurrentContent().getPlainText();
    setSelection(text.slice(selectionRange[0], selectionRange[1]));
  }, [editor.editorStates, currentLocale]);

  const editorClasses = () => {
    let classes = ["text-editor", "comparable"];
    if (activeEditor !== null && editor.id !== activeEditor) classes.push("text-editor--inactive");
    if (activeEditor !== null && editor.id === activeEditor) classes.push("text-editor--active");
    if (
      editor.editorStates[currentLocale].getSelection().getAnchorOffset() -
        editor.editorStates[currentLocale].getSelection().getFocusOffset() !==
      0
    )
      classes.push("text-editor--selected-content");
    return classes.join(" ");
  };

  const labelIsActive = (panel: Panel) => {
    return activePanel === panel ? " text-editor__field-title--active" : "";
  };

  const getVersionContent = () => {
    const version = versions.find((version) => version.id === AppState.state.showVersion);

    const fieldVersion = version?.fieldVersions.find(
      (fieldVersion) => fieldVersion.fieldTypeId === editor.fieldTypeId
    );
    const content = fieldVersion?.text;
    if (content) {
      return content;
    }
    return "";
  };

  const getComparisonVersionContent = () => {
    if (AppState.state.showComparisonVersion === 0) {
      return editor.editorStates[comparisonLocale].getCurrentContent().getPlainText();
    }
    const version = versions.find((version) => version.id === AppState.state.showComparisonVersion);
    const fieldVersion = version?.fieldVersions.find(
      (fieldVersion) => fieldVersion.fieldTypeId === editor.fieldTypeId
    );
    const content = fieldVersion?.text;
    if (content) {
      return content;
    }
    return "";
  };

  const handleFieldClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    handleEditorFocus(editor.id, "main");
  };

  const hasContent = editor.editorStates[currentLocale].getCurrentContent().hasText();
  const hasComparisonContent = editor.editorStates[comparisonLocale].getCurrentContent().hasText();

  return (
    <div className={editorClasses()} style={{ position: "relative" }}>
      {GlobalState.state.showComparison && (
        <div
          className={`comparable__item comparable__item--comparison`}
          style={{
            flex: `${comparisonWidth} 1 0px`
          }}
        >
          <span className="text-editor__label-container">
            <h2 className={"text-editor__field-title" + labelIsActive("comparison")}>
              {editor.label}
            </h2>

            <div className="text-editor__button-container">
              <span className="text-editor__readonly-label">Read-only</span>
              {hasComparisonContent && (
                <span
                  className="text-editor__translation-label"
                  onClick={() => onTranslate(editor)}
                >
                  Translate to {currentLocale}
                </span>
              )}
            </div>
          </span>
          <div
            className="text-editor__field-container"
            onClick={(e: SyntheticEvent) => {
              e.stopPropagation();
            }}
            onFocus={() => handleEditorFocus(editor.id, "comparison")}
          >
            {AppState.state.showVersion === 0 ? (
              <Editor
                editorState={EditorState.acceptSelection(
                  editor.editorStates[comparisonLocale],
                  SelectionState.createEmpty(
                    editor.editorStates[comparisonLocale]
                      .getCurrentContent()
                      .getFirstBlock()
                      .getKey()
                  )
                )}
                readOnly={!AppState.state.isEditable}
                onChange={(state) => handleChange(state, editor.id, "comparison", comparisonLocale)}
              />
            ) : (
              <StringDiff
                method={DiffMethod.Sentences}
                styles={stringDiffStyles}
                oldValue={getVersionContent()}
                newValue={getComparisonVersionContent()}
              />
            )}
          </div>
        </div>
      )}
      <div
        className={`comparable__item ${
          !AppState.state.isEditable && "comparable__item--comparison"
        }`}
        style={{
          flex: `${mainWidth} 1 0px`
        }}
      >
        <div className={`${loading ? "loading-translation" : ""}`}>
          <span className="text-editor__label-container">
            <h2
              className={"text-editor__field-title" + labelIsActive("main")}
              onClick={(e) => {
                e.stopPropagation();
                handleEditorFocus(editor.id, "main");
              }}
            >
              {editor.label}
            </h2>
            {editor.description && (
              <span className="text-editor__field-description" data-tip={editor.description}>
                ?
              </span>
            )}

            {errorMessage && (
              <span className="text-editor__field-error" data-tip={errorMessage}>
                !
              </span>
            )}

            {AppState.state.isEditable && GlobalState.state.showComparison && hasContent && (
              <span
                onClick={() => onReTranslate(editor)}
                className={"text-editor__re-translation-label"}
              >
                Retranslate
              </span>
            )}
          </span>
          {!AppState.state.isEditable && (
            <span className="text-editor__readonly-label">Read-only</span>
          )}
          <div className="text-editor__field-container" onClick={handleFieldClick}>
            <Editor
              ref={editorRef}
              editorState={editor.editorStates[currentLocale]}
              onChange={(state) => handleChange(state, editor.id, "main", currentLocale)}
              onFocus={() => handleEditorFocus(editor.id, "main")}
            />
            {children}
          </div>

          {editor.editorStates[currentLocale].getSelection().getAnchorOffset() -
            editor.editorStates[currentLocale].getSelection().getFocusOffset() !==
            0 && (
            <button
              className="text-editor__add-comment-btn"
              onClick={(e) => {
                e.stopPropagation();
                handleEditorFocus(editor.id, "main");
                setQuote(selection);
                AppState.dispatch({ type: AppStateAction.setShowNewComment, payload: true });
              }}
            ></button>
          )}
        </div>
      </div>
    </div>
  );
};

//This was apparently needed to avoid some typescript error, unclear why this solves anything

const whitespace: "pre-line" = "pre-line";

const stringDiffStyles = {
  added: {
    backgroundColor: "rgb(210,246,231)",
    color: "rgb(6,119,75)",
    whiteSpace: whitespace
  },
  removed: {
    backgroundColor: "rgb(255,220,236)",
    color: "rgb(209,18,71)",
    textDecoration: "line-through",
    whiteSpace: whitespace
  },
  default: {
    display: "inline-block",
    whiteSpace: whitespace
  }
};

export default TextEditor;
