import { LexiconComment, SubcommentInput } from "../types";
import CommentItem from "./CommentItem";
interface Props {
  comments: LexiconComment[];
  filter: "unresolved" | "resolved";
  resolveComment: (id: number, status: boolean) => void;
  deleteComment: (id: number) => void;
  deleteSubcomment: (id: number) => void;
  submitNewSubcomment: (input: SubcommentInput) => void;
  key?: "string";
}

const CommentsList = (props: Props) => {
  const {
    comments,
    resolveComment,
    deleteComment,
    submitNewSubcomment,
    deleteSubcomment,
    filter,
    key,
  } = props;
  const filterComments = () => {
    if (filter === "resolved") return comments.filter((comment) => comment.resolved === true);
    return comments.filter((comment) => comment.resolved === false);
  };
  return (
    <div className="comments-list" key={key}>
      {comments.length > 0 &&
        filterComments().length > 0 &&
        filterComments().map((comment) => (
          <CommentItem
            comment={comment}
            key={comment.id}
            resolveComment={resolveComment}
            deleteComment={deleteComment}
            deleteSubcomment={deleteSubcomment}
            submitNewSubcomment={submitNewSubcomment}
          />
        ))}
    </div>
  );
};

export default CommentsList;
