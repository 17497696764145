import { PhraseMatch } from "kb-lexicon/dist/src/types/Interfaces";
import PhraseHeader from "./PhraseHeader";
import PhraseSuggestions from "./PhraseSuggestions";
import { Panel } from "../types";

interface Props {
  activeEditor: number | null;
  activePanel: Panel;
  activePhrase: PhraseMatch;
  setActivePhrase: (arg: PhraseMatch | null) => void;
}

const PhraseViewer = ({ setActivePhrase, activeEditor, activePhrase, activePanel }: Props) => {
  return (
    <>
      <PhraseHeader
        setActivePhrase={setActivePhrase}
        activeEditor={activeEditor}
        phrase={activePhrase.phrase}
      />
      <div className="sidebar__container">
        <PhraseSuggestions phrase={activePhrase.phrase} activePanel={activePanel} />
      </div>
    </>
  );
};

export default PhraseViewer;
