import React, { useState } from "react";
import formatDate from "../helpers/formatDate";
import { useEntity } from "./contextProviders/EntityProvider";
import VersionItem from "./VersionItem";
import VersionsList from "./VersionsList";
import { ReactComponent as CloseIcon } from "../img/icon-close-black.svg";
import OutsideClickHandler from "react-outside-click-handler";
import rightArrow from "../img/icon-arrow-right.svg";
interface Props {
  versionId: number;
  replacementText?: string;
  setVersion: (id: number) => void;
}

const VersionSelector: React.FC<Props> = ({ children, versionId, setVersion, replacementText }) => {
  const [showVersionsList, setShowVersionsList] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const entity = useEntity();

  const handleNext = () => {
    if (!(entity.versionsInfo.loadedPages >= page + 1)) {
      entity.getNextVersionsPage();
    }
    setPage(page + 1);
  };

  const handlePrev = () => {
    setPage(page - 1);
  };

  const handleClick = () => {
    setShowVersionsList(!showVersionsList);
  };

  const activeVersion = entity.versions.find((version) => version.id === versionId);
  return (
    <div className="version-selector">
      <OutsideClickHandler onOutsideClick={() => setShowVersionsList(false)}>
        <div className="version-selector__button-container">
          <button onClick={handleClick} className="version-selector__active-version-button">
            {activeVersion ? (
              <>
                <span style={{ fontWeight: "bold" }}>{activeVersion?.step.title}</span>{" "}
                <span>{formatDate(activeVersion.created)[0]}</span>
              </>
            ) : (
              <strong>{replacementText}</strong>
            )}
          </button>
          {replacementText && activeVersion && (
            <button className="version-selector__button-close" onClick={() => setVersion(0)}>
              <CloseIcon></CloseIcon>
            </button>
          )}
        </div>
        {showVersionsList && (
          <div className="version-selector__versions-container">
            <VersionsList className="version-selector__versions-list">
              {children}
              {entity.versions
                .slice(
                  page * entity.versionsInfo.pageSize,
                  page * entity.versionsInfo.pageSize + entity.versionsInfo.pageSize
                )
                .map((version) => (
                  <VersionItem
                    version={version}
                    className="version-selector__version-item"
                    key={`version_item_${version.id}`}
                    active={versionId === version.id}
                    onClick={() => {
                      setVersion(version.id);
                      setShowVersionsList(false);
                    }}
                  />
                ))}
            </VersionsList>
            {entity.versionsInfo.count > entity.versionsInfo.pageSize && (
              <>
                <div className="version-selector__button-container">
                  <button
                    onClick={handlePrev}
                    disabled={page === 0}
                    className="version-selector__button"
                  >
                    <img src={rightArrow} alt="" style={{ transform: "rotate(180deg)" }} />
                  </button>
                  <span>
                    {page * entity.versionsInfo.pageSize} -{" "}
                    {(page + 1) * entity.versionsInfo.pageSize} ({entity.versionsInfo.count})
                  </span>
                  <button
                    onClick={handleNext}
                    disabled={
                      entity.versionsInfo.count <= (page + 1) * entity.versionsInfo.pageSize
                    }
                    className="version-selector__button"
                  >
                    <img src={rightArrow} alt="" />
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default VersionSelector;
