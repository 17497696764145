import React from "react";
import { ReactComponent as Preview } from "../../img/icon-preview.svg";
import { ReactComponent as PreviewActive } from "../../img/icon-preview-active.svg";
import { ReactComponent as Compare } from "../../img/icon-translate.svg";
import { ReactComponent as CompareActive } from "../../img/icon-translate-active.svg";
import { ReactComponent as Filter } from "../../img/icon-filter-list-off.svg";
import { ReactComponent as FilterActive } from "../../img/icon-filter-list-off-active.svg";
import OptionsItem from "../OptionsItem";
import { GlobalAppStateAction, useGlobalState } from "../contextProviders/GlobalAppStateProvider";
import { useLocales } from "../contextProviders/LocaleProvider";
import OptionsBarLocaleSelector from "../molecules/OptionsBarLocaleSelector";
import getColumnWidths from "../../helpers/getColumnWidths";
import { useConfig } from "../contextProviders/ConfigProvider";
interface Props {}

const OptionsBar: React.FC<Props> = () => {
  const GlobalState = useGlobalState();
  const config = useConfig();
  const locales = useLocales();
  const [mainWidth, comparisonWidth] = getColumnWidths(
    locales.currentLocale,
    locales.comparisonLocale,
    config
  );
  const handleClick = (e: any) => {
    switch (e.currentTarget.id) {
      case "comparison":
        GlobalState.dispatch({
          type: GlobalAppStateAction.setShowComparison,
          payload: !GlobalState.state.showComparison
        });
        break;
      case "showFields":
        GlobalState.dispatch({
          type: GlobalAppStateAction.setShowAllFields,
          payload: !GlobalState.state.showAllFields
        });
        break;
      case "showPreview":
        GlobalState.dispatch({
          type: GlobalAppStateAction.setShowPreview,
          payload: !GlobalState.state.showPreview
        });
        break;
      default:
        break;
    }
  };

  const getContainerClasses = () => {
    const classes = ["options-bar"];
    if (GlobalState.state.showComparison) classes.push("options-bar--comparison");
    return classes.join(" ");
  };

  return (
    <>
      <div className="options-bar__spacer" />
      <div className={getContainerClasses()}>
        <div className="options-bar__locales-container">
          {GlobalState.state.showComparison && (
            <OptionsBarLocaleSelector
              locales={locales.availableLocales}
              currentLocale={locales.comparisonLocale}
              setLocale={(locale) => locales.setComparisonLocale(locale)}
              label={"Comparison Language: "}
              style={{
                flex: `${comparisonWidth} 1 0px`
              }}
            />
          )}
          <OptionsBarLocaleSelector
            locales={locales.availableLocales}
            currentLocale={locales.currentLocale}
            label={"Now editing: "}
            setLocale={(locale) => locales.setCurrentLocale(locale)}
            style={{
              flex: `${mainWidth} 1 0px`
            }}
          />
        </div>
        <div className="options-bar__button-container">
          <OptionsItem
            padding=""
            icon={Preview}
            iconActive={PreviewActive}
            onClick={handleClick}
            name="showPreview"
            tooltip="Show preview"
            isActive={GlobalState.state.showPreview}
          />
          <OptionsItem
            padding=""
            icon={Compare}
            iconActive={CompareActive}
            onClick={handleClick}
            name="comparison"
            tooltip="Compare text in different languages"
            isActive={GlobalState.state.showComparison}
          />
          <OptionsItem
            padding=""
            icon={Filter}
            iconActive={FilterActive}
            onClick={handleClick}
            name="showFields"
            tooltip="Show filtered fields"
            isActive={GlobalState.state.showAllFields}
          />
        </div>
      </div>
    </>
  );
};

export default OptionsBar;
