import { AppStateAction, useAppState } from "./contextProviders/AppStateProvider";
interface Props {
  commentCount: number;
  issueCount: number;
}
const SidebarNavigation = ({ commentCount, issueCount }: Props) => {
  const AppState = useAppState();
  return (
    <div className="sidebar-navigation">
      <button
        className={`sidebar-navigation__item ${
          AppState.state.sidebarView === "issues" ? "sidebar-navigation__item--active" : ""
        }`}
        onClick={() =>
          AppState.dispatch({ type: AppStateAction.setSidebarView, payload: "issues" })
        }
      >
        Lexicon <span className="sidebar-navigation__item-notification">{issueCount}</span>
      </button>
      <button
        className={`sidebar-navigation__item sidebar-navigation__item ${
          AppState.state.sidebarView === "comments" ? "sidebar-navigation__item--active" : ""
        }`}
        onClick={() =>
          AppState.dispatch({ type: AppStateAction.setSidebarView, payload: "comments" })
        }
      >
        Comments <span className="sidebar-navigation__item-notification">{commentCount}</span>
      </button>
    </div>
  );
};

export default SidebarNavigation;
