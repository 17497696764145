import { ChangeEvent, FC } from "react";

interface Props {
  textAreaClass?: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  name: string;
  error: string;
  placeholder?: string;
  [key: string]: any;
}

const TextArea: FC<Props> = (props) => {
  const {
    error,
    value,
    name,
    onChange: handleChange,
    textAreaClass,
    placeholder,
    children,
    ...rest
  } = props;
  return (
    <>
      <div className={"text-area " + textAreaClass}>
        <textarea
          className="text-area__field"
          id={name}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          {...rest}
        />
        {children}
      </div>

      {error && (
        <div className="text-area__error-message">
          <strong>Just a heads up:</strong> {error}
        </div>
      )}
    </>
  );
};

export default TextArea;
