import axios from "axios";

const GPT_API_KEY = process.env.REACT_APP_GPT_API_KEY;

export type EditorText = { id: number; text: string };

async function translateText(
  sourceLanguage: string,
  targetLanguage: string,
  inputText: string
): Promise<string> {
  const apiKey = `Bearer ${GPT_API_KEY}`;
  const apiUrl = "https://api.openai.com/v1/chat/completions";

  const prompt = `Translate from ${sourceLanguage} to ${targetLanguage}:\n"${inputText}". Exclude comments and dont add any quoutes at the start and end of the text.`;
  const chatTokens = [
    // { role: 'system', content: 'You are a helpful assistant that translates text.' },
    { role: "user", content: prompt }
  ];

  try {
    const response = await axios.post(
      apiUrl,
      {
        model: "gpt-3.5-turbo",
        messages: chatTokens,
        //max_tokens: 50,
        temperature: 0,
        // stop: "\n",
        //n: 1,
        user: "assistant"
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": apiKey
        }
      }
    );

    const reply = response.data.choices[0].message.content;
    return reply.trim();
  } catch (error) {
    console.error("Error translating text:", error);
    throw error;
  }
}

const translateEditorText = (from: string, to: string, editorText: EditorText) => {
  return translateText(from, to, editorText.text)
    .then((translatedText) => {
      return {
        id: editorText.id,
        text: translatedText
      };
    })
    .catch((error) => {
      console.error("Error translating editor text:", error);
      throw error;
    });
};

const GPTAPI = {
  translateText,
  translateEditorText
};

export default GPTAPI;
