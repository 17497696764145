const getEntityQuery = (entityId: number, modelId: number) => {
  return `
  query{
    readOneSourceEntity(filter:{
      externalId: {
        eq: ${entityId},
      }
      modelID: {
        eq: ${modelId}
      }
    }){
      id,
      fields{
        id,
        fieldTypeId,
        fieldModel{
          workflowSteps{
            id
          }
        }
      }
      currentStep{
        id,
      }
    }
  }
  `;
};

export default getEntityQuery;
