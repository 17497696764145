import { Phrase, SuggestedPhrase } from "kb-lexicon/dist/src/types/Interfaces";
import { useContext, useEffect, useState } from "react";
import { Panel } from "../types";
import { LexiconContext } from "./contextProviders/LexiconProvider";
import { useLocales } from "./contextProviders/LocaleProvider";
import LocaleSelector from "./LocaleSelector";

interface Props {
  phrase: Phrase;
  activePanel: Panel;
}

const PhraseSuggestions: React.FC<Props> = ({ phrase, activePanel }) => {
  const { lexicon } = useContext(LexiconContext);
  const [activeTab, setActiveTab] = useState<"suggestions" | "translations">("suggestions");
  const { currentLocale, availableLocales } = useLocales();
  const [comparisonLocale, setComparisonLocale] = useState<string>(currentLocale);
  const btnClasses = {
    suggestions: activeTab === "suggestions" ? "--active" : "",
    translations: activeTab === "translations" ? "--active" : ""
  };
  useEffect(() => {
    if (activePanel === "comparison") {
      setActiveTab("translations");
    } else {
      setActiveTab("suggestions");
    }
  }, [activePanel]);

  const getList = () => {
    const nonEmptyFilter = (phrase: SuggestedPhrase) => {
      return !!phrase.title;
    };

    if (activeTab === "suggestions")
      return (
        <dl className="phrase-suggestions__list">
          {phrase.suggestedPhrases.filter(nonEmptyFilter).map((suggestedPhrase) => (
            <div key={suggestedPhrase.id} className="phrase-suggestions__item">
              <dt className="phrase-suggestions__term">{suggestedPhrase.title}</dt>
              <dd className="phrase-suggestions__description">{suggestedPhrase.description}</dd>
            </div>
          ))}
        </dl>
      );
    else {
      if (lexicon.hasLoadedLocale(comparisonLocale)) {
        const comparisonPhrase: Phrase = lexicon.getPhrase(phrase.id, comparisonLocale);
        return (
          <>
            <LocaleSelector
              locales={availableLocales}
              currentLocale={comparisonLocale}
              setLocale={setComparisonLocale}
            />
            <dl className="phrase-suggestions__list">
              <div key={comparisonPhrase.id} className="phrase-suggestions__main-item">
                <dt className="phrase-suggestions__term">{comparisonPhrase.title}</dt>
                <dd className="phrase-suggestions__description">{comparisonPhrase.description}</dd>
              </div>
              {comparisonPhrase.suggestedPhrases.filter(nonEmptyFilter).map((suggestedPhrase) => (
                <div key={suggestedPhrase.id} className="phrase-suggestions__item">
                  <dt className="phrase-suggestions__term">{suggestedPhrase.title}</dt>
                  <dd className="phrase-suggestions__description">{suggestedPhrase.description}</dd>
                </div>
              ))}
            </dl>
          </>
        );
      }
      return;
    }
  };

  const list = getList();
  return (
    <div className="phrase-suggestions">
      <div className="phrase-suggestions__action-bar">
        <button
          className={"phrase-suggestions__btn" + btnClasses.suggestions}
          onClick={() => setActiveTab("suggestions")}
        >
          Suggestions
        </button>
        <button
          className={"phrase-suggestions__btn" + btnClasses.translations}
          onClick={() => setActiveTab("translations")}
        >
          Translations
        </button>
      </div>
      {list}
    </div>
  );
};

export default PhraseSuggestions;
