import axios from "axios";
import { Lexicon } from "kb-lexicon";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { useLocales } from "./LocaleProvider";

interface Props {}

interface LexiconContextInterface {
  lexicon: any;
  errors: any;
  isLoading: boolean;
  hasUpdated: boolean;
  setHasUpdated: (bool: boolean) => void;
}

export const LexiconContext = createContext<LexiconContextInterface>({
  lexicon: null,
  errors: null,
  isLoading: false,
  hasUpdated: false,
  setHasUpdated: (bool: boolean) => {},
});

const LexiconProvider: React.FC<Props> = ({ children }) => {
  const { currentLocale, comparisonLocale, availableLocales } = useLocales();
  const [lexicon] = useState(new Lexicon());
  const [errors, setErrors] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasUpdated, setHasUpdated] = useState(false);
  const loadLexicon = useCallback(
    async (locale: string) => {
      setIsLoading(true);
      try {
        lexicon.setApiEndpoint(process.env.REACT_APP_LEXICON_RESTAPI_ENDPOINT + "/getPhrases");
        const auth = process.env.REACT_APP_LEXICON_AUTH_HEADER;
        auth && lexicon.setAuthHeader(auth);
        axios.defaults.headers.common["Authorization"] = process.env.REACT_APP_LEXICON_RESTKEY;
        await lexicon.fetchData(locale);
        setIsLoading(false);
        setHasUpdated(true);
      } catch (error) {
        setErrors(error);
      }
    },
    [lexicon]
  );

  useEffect(() => {
    if (!lexicon.hasLoadedLocale(currentLocale)) {
      lexicon.setLocale(currentLocale);
      loadLexicon(currentLocale);
    } else {
      lexicon.setLocale(currentLocale);
      setHasUpdated(true);
    }
  }, [currentLocale, availableLocales, lexicon, loadLexicon]);

  useEffect(() => {
    setHasUpdated(true);
  }, [comparisonLocale]);

  useEffect(() => {
    availableLocales.forEach((locale) => {
      if (!lexicon.hasLoadedLocale(locale.locale)) {
        loadLexicon(locale.locale);
      }
    });
  }, [availableLocales, loadLexicon, lexicon]);

  const contextValue = { lexicon, errors, isLoading, hasUpdated, setHasUpdated };
  return <LexiconContext.Provider value={contextValue}>{children}</LexiconContext.Provider>;
};

export default LexiconProvider;
