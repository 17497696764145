import { PhraseMatch } from "kb-lexicon/dist/src/types/Interfaces";

interface Props {
  hit: PhraseMatch;
  setActivePhrase: (phraseMatch: PhraseMatch | null) => void;
}

const HitItem = ({ hit, setActivePhrase }: Props) => {
  return (
    <li
      className={`hits-list__item hits-list__item--${hit.phrase.warningLevel.toLocaleLowerCase()}`}
    >
      <button className="hits-list__link" onClick={() => setActivePhrase(hit)}>
        {hit.phrase.title} <span>{hit.matches.length} hits</span>
      </button>
    </li>
  );
};

export default HitItem;
