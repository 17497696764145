import { ContentState, EditorState } from "draft-js";
import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Editor, FieldValue, InRiverField, Panel } from "../../types";
import { AppStateAction, useAppState } from "./AppStateProvider";
import { useEntity, useWorkflow } from "./EntityProvider";
import { useFields } from "./FieldsProvider";
import { useLocales } from "./LocaleProvider";

export interface EditorContext {
  editors: Editor[];
  setEditors: (editors: Editor[]) => void;
  filter: string[];
  hasLoaded: boolean;
  activeEditor: Editor | null | undefined;
  activeEditorId: number | null;
  setActiveEditorId: (id: number | null) => void;
  activePanel: Panel;
  setActivePanel: (panel: Panel) => void;
  save: (options?: SaveOptions) => void;
}

type SaveOptions = {
  onSuccess?: () => void;
  onError?: () => void;
  onAfter?: () => void;
};

const editorContext = createContext<EditorContext>({
  editors: [],
  setEditors: (arr: Editor[]) => {},
  filter: [],
  hasLoaded: false,
  activeEditor: null,
  activeEditorId: null,
  setActiveEditorId: (id: number | null) => {},
  activePanel: "main",
  setActivePanel: (panel: Panel) => {},
  save: (options?: SaveOptions) => {}
});

export const useEditors = () => {
  return useContext(editorContext);
};

const EditorProvider: React.FC = ({ children }) => {
  const { fields, isLoading: fieldsLoading } = useFields();
  const Entity = useEntity();
  const { dispatch } = useAppState();
  const { localeMap, isLoading: localesLoading, currentLocale } = useLocales();
  const [editors, setEditors] = useState<Editor[]>([]);
  const { saveFields } = useFields();
  const { state: AppState } = useAppState();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [activePanel, setActivePanel] = useState<Panel>("main");
  const [activeEditor, setActiveEditor] = useState<Editor | null | undefined>(); // No idea why I decided to do this, should be using the activeEditorId instead of keeping an entire editor in state.
  const [activeEditorId, setActiveEditorId] = useState<null | number>(null);
  const [filter, setFilter] = useState<string[]>([]); // This is the filter that will be applied to editors to only show the fields related to the current workflow step, according to lms settings.
  const workflow = useWorkflow();
  const [isUnsaved, setIsUnsaved] = useState(false);

  const anyFieldHasChanged = (editors: Editor[]) => {
    return fields.reduce((acc, field) => {
      const inRiverLocale = Object.fromEntries(
        Object.entries(localeMap).map(([key, value]) => [value, key])
      )[currentLocale];
      const editor = editors.find((editor) => editor.label === field.fieldTypeDisplayName);
      if (!editor) return acc;
      const editorText = editor?.editorStates[currentLocale].getCurrentContent().getPlainText();
      const fieldText = field.value?.[inRiverLocale];

      if (editorText !== fieldText) {
        return true;
      }
      return acc;
    }, false);
  };

  // Check for unsaved changes and warn the user before leaving the page.
  useEffect(() => {
    const beforeUnloadListener = (event: any) => {
      event.preventDefault();
      return (event.returnValue = "");
    };

    if (isUnsaved) {
      window.addEventListener("beforeunload", beforeUnloadListener, { capture: true });
    } else {
      window.removeEventListener("beforeunload", beforeUnloadListener, {
        capture: true
      });
    }

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener, {
        capture: true
      });
    };
  }, [isUnsaved]);

  useEffect(() => {
    if (!fieldsLoading && fields.length > 0 && !hasLoaded && !localesLoading) {
      const lmsStageField = fields.find((field) =>
        field.fieldTypeId.includes("LmsStage")
      )?.fieldTypeId;

      const filteredFields = filterFields(fields);

      const tmpEditors = formatFields(filteredFields, localeMap);

      // This is used when setting an entity as "completed" in InRiver. Not available on all entities.
      if (lmsStageField) {
        dispatch({ type: AppStateAction.setLmsStageField, payload: lmsStageField });
      }

      setEditors(tmpEditors);
      setHasLoaded(true);
    }
  }, [fields, fieldsLoading, Entity.id, hasLoaded, localesLoading, localeMap, dispatch]);

  useEffect(() => {
    setActiveEditor(editors.find((editor) => editor.id === activeEditorId));
  }, [activeEditorId, editors]);

  useEffect(() => {
    if (hasLoaded) {
      const getFilteredFieldTypes = () => {
        // Add filters based on WorkflowSteps
        const entityFields = Entity.fields;
        const filteredFields = workflow.id
          ? entityFields.filter(
              (field) => !field.workflowSteps.map((step) => step.id).includes(workflow.currentStep)
            )
          : [];
        const filteredFieldTypeIds = filteredFields.map((field) => field.fieldTypeId);

        const localeStringFields = fields.filter((field) => field.fieldDataType === "LocaleString");

        // Add filters based on available fields in the model
        const modelFieldIds = Entity.LMSFieldModels.map((model) => model.typeId);
        const fieldsNotInModel = fields.filter(
          (field) =>
            !modelFieldIds.includes(field.fieldTypeId) &&
            localeStringFields.map((field) => field.fieldTypeId).includes(field.fieldTypeId)
        );
        fieldsNotInModel.forEach((field) => filteredFieldTypeIds.push(field.fieldTypeId));

        // Add filters based on available fields in the LMS EntityProvider
        const entityFieldIds = entityFields.map((field) => field.fieldTypeId);
        const fieldsNotOnEntity = fields.filter(
          (field) =>
            !entityFieldIds.includes(field.fieldTypeId) &&
            localeStringFields.map((field) => field.fieldTypeId).includes(field.fieldTypeId)
        );

        fieldsNotOnEntity.forEach((field) => filteredFieldTypeIds.push(field.fieldTypeId));

        // Add filters based on fieldSet;
        filteredFieldTypeIds.push(
          ...fields
            .filter((field) => {
              return (
                !field.includedInFieldSets?.includes(Entity.fieldSet) &&
                field.isExcludedFromDefaultView
              );
            })
            .map((field) => field.fieldTypeId)
        );

        return filteredFieldTypeIds;
      };

      setFilter(getFilteredFieldTypes());
    }
  }, [
    workflow.currentStep,
    Entity.fields,
    Entity.fieldSet,
    Entity.LMSFieldModels,
    workflow.id,
    workflow.steps,
    hasLoaded,
    fields
  ]);

  // Gets the plain text in an editor, used in saving to inriver
  const getEditorData = (editor: Editor): FieldValue => {
    let values: { [key: string]: string } = {};
    const inRiverLocales = Object.keys(localeMap);
    inRiverLocales.forEach((inRiverLocale) => {
      if (Object.prototype.hasOwnProperty.call(editor.editorStates, localeMap[inRiverLocale])) {
        values[inRiverLocale] = editor.editorStates[localeMap[inRiverLocale]]
          .getCurrentContent()
          .getPlainText();
      }
    });

    return {
      fieldTypeId: editor.fieldTypeId,
      value: values
    };
  };

  const save = (
    options: SaveOptions = { onSuccess: () => {}, onError: () => {}, onAfter: () => {} }
  ) => {
    const data: FieldValue[] = [];

    // Get field data from each editor
    editors.forEach((editor) => {
      data.push(getEditorData(editor));
    });

    let onLMSUpdated: (() => void) | undefined = undefined;
    const lmsField = fields.find((field) => field.fieldTypeId === AppState.lmsStageField)?.value;
    const hasEmptyLmsStage = Object.keys(lmsField).reduce((acc: boolean, curr: string) => {
      return acc || !lmsField[curr];
    }, false);

    if (hasEmptyLmsStage && AppState.lmsStageField && Object.keys(localeMap).length) {
      // Check if there is a workflow, if none and there is an LMS Stage field on the model. Set the LMS Stage to completed
      if (!workflow.id) {
        let values: { [key: string]: string } = {};
        Object.keys(localeMap).forEach((InRiverLocale) => {
          values[InRiverLocale] = "Completed";
        });
        data.push({
          fieldTypeId: AppState.lmsStageField,
          value: values
        });
        onLMSUpdated = () => {
          toast.success("Updated LMS Stage");
        };
      }
      // Check if the LMS Field is empty and update it with the correct status
      else {
        const currentValue = fields.find((field) => field.fieldTypeId === AppState.lmsStageField);
        const values = currentValue?.value;

        let newValues: any = {};
        if (values) {
          // for each value check if empty
          Object.keys(localeMap).forEach((InRiverLocale) => {
            if (!values[InRiverLocale]) {
              // Check if entity is in last step of workflow
              if (
                workflow.id &&
                workflow.currentStep === workflow.steps[workflow.steps.length - 1].id
              ) {
                newValues[InRiverLocale] = "Completed";
              } else {
                newValues[InRiverLocale] = "In Progress";
              }
            } else {
              newValues[InRiverLocale] = values[InRiverLocale];
            }
          });

          if (Object.keys(newValues).length) {
            console.log("newValues", newValues);

            data.push({
              fieldTypeId: AppState.lmsStageField,
              value: newValues
            });
            onLMSUpdated = () => {
              toast.success("Updated LMS Stage");
            };
          }

          // if empty set to "In Progress"
          // if not empty do nothing
          // push to data
        }
      }
    } else {
      console.log("Should not update LMS Stage");
    }

    // Set the appstate to saving to prevent further saves
    dispatch({ type: AppStateAction.setIsSaving, payload: true });

    // Save the data to inRiver
    saveFields(data)
      .then(() => {
        if (options?.onSuccess) options.onSuccess();
        if (onLMSUpdated) onLMSUpdated();
        setIsUnsaved(false);
      })
      .catch((err) => {
        toast.error("Error while saving");
        console.log("Error saving data to inRiver: ", err);
        if (options?.onError) options.onError();
      })
      .finally(() => {
        dispatch({ type: AppStateAction.setIsSaving, payload: false });
        if (options?.onAfter) options.onAfter();
      });
  };

  const contextValue = {
    editors,
    setEditors: (editors: Editor[]) => {
      if (anyFieldHasChanged(editors)) {
        setIsUnsaved(true);
      }
      setEditors(editors);
    },
    filter,
    hasLoaded,
    activeEditor,
    activeEditorId,
    setActiveEditorId,
    activePanel,
    setActivePanel,
    save
  };

  return <editorContext.Provider value={contextValue}>{children}</editorContext.Provider>;
};

const filterFields = (fields: InRiverField[]) => {
  return fields.filter((field) => {
    return field.fieldDataType === "LocaleString" && !field.fieldTypeId.includes("LmsStage");
  });
};

const formatFields = (fields: InRiverField[], localeMap: { [key: string]: any }) => {
  const editorFields = fields.map((field, index) => {
    let editorStates: { [key: string]: EditorState } = {};
    for (const locale in field.value) {
      if (Object.prototype.hasOwnProperty.call(field.value, locale)) {
        editorStates[localeMap[locale]] = EditorState.createWithContent(
          ContentState.createFromText(field.value[locale])
        );
      }
    }
    return {
      id: index,
      fieldTypeId: field.fieldTypeId,
      label: field.fieldTypeDisplayName,
      description: field.fieldTypeDescription,
      editorStates: editorStates,
      oldVersions: {}
    };
  });

  return editorFields;
};

export default EditorProvider;
