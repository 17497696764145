const createFieldVersionMutation = (text: string, entityVersionId: number, fieldId: number) => {
  return `
    mutation{
      createFieldVersion(input: {
        entityVersionID: ${entityVersionId},
        fieldID: ${fieldId}
        text: ${JSON.stringify(text)}
      }){
        text,
        field{
          fieldTypeId
        }
      }
    }
  `;
};

export default createFieldVersionMutation;
