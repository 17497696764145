import { useState } from "react";
import { LexiconComment, SubcommentInput } from "../types";
import CommentsList from "./CommentsList";

interface Props {
  fields: {
    [key: string]: {
      comments: LexiconComment[];
      label: string;
    };
  };
  resolveComment: (id: number, bool: boolean) => void;
  deleteComment: (id: number) => void;
  deleteSubcomment: (id: number) => void;
  submitNewSubcomment: (input: SubcommentInput) => void;
}

const FieldCommentList = (props: Props) => {
  const { fields, resolveComment, deleteComment, deleteSubcomment, submitNewSubcomment } = props;
  const [filter, setFilter] = useState<"unresolved" | "resolved">("unresolved");

  return (
    <>
      <div className="comments-list__filter">
        <button
          className={`comments-list__filter-button ${
            filter === "unresolved" ? "comments-list__filter-button--active" : ""
          }`}
          onClick={() => setFilter("unresolved")}
        >
          Unresolved
        </button>
        <button
          className={`comments-list__filter-button ${
            filter === "resolved" ? "comments-list__filter-button--active" : ""
          }`}
          onClick={() => setFilter("resolved")}
        >
          Resolved
        </button>
      </div>
      <div className="field-comment-list">
        {Object.keys(fields).length &&
          Object.keys(fields).map(
            (key, index) =>
              fields[key].comments.filter((comment) =>
                filter === "resolved" ? comment.resolved : !comment.resolved
              ).length > 0 && (
                <div className="field-comment-list__list-item" key={index}>
                  {Object.keys(fields).length > 1 && (
                    <h3 className="field-comment-list__label">{fields[key].label.toUpperCase()}</h3>
                  )}

                  <CommentsList
                    comments={fields[key].comments}
                    resolveComment={resolveComment}
                    deleteComment={deleteComment}
                    deleteSubcomment={deleteSubcomment}
                    submitNewSubcomment={submitNewSubcomment}
                    filter={filter}
                  />
                </div>
              )
          )}
      </div>
    </>
  );
};

export default FieldCommentList;
