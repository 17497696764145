import create from "zustand";

export type PreviewStore = {
  nodes: { [key: number]: EntityContent };
  addNode: (node: EntityContent) => void;
  updateNode: (entityId: number, fields: Field[], comparisonFields: Field[]) => void;
  removeNode: (entityId: number) => void;
};

export type EntityContent = {
  entityId: number;
  parentId: number;
  sortOrder: number;
  fields: Field[];
  comparisonFields: Field[];
};

export type Field = {
  fieldTypeId: string;
  element: HeadingLevel | "p";
  content: string;
  isFiltered: boolean;
};

export type HeadingLevel = "h1" | "h2" | "h3";
export type TagType = HeadingLevel | "p";

export const usePreviewStore = create<PreviewStore>((set) => ({
  nodes: {},
  comparisonNodes: {},
  addNode: (node: EntityContent) => {
    return set((state) => {
      if (!Object.keys(state.nodes).includes(node.entityId.toString())) {
        return {
          ...state,
          nodes: {
            ...state.nodes,
            [node.entityId]: node
          }
        };
      }
      return state;
    });
  },
  updateNode: (entityId, fields, comparisonFields) => {
    return set((state) => {
      return {
        ...state,
        nodes: {
          ...state.nodes,
          [entityId]: {
            ...state.nodes[entityId],
            fields,
            comparisonFields
          }
        }
      };
    });
  },
  removeNode: () => {}
}));
