import { RetranslationEditor } from "../types";
import GPTAPI from "./GPTAPI";
import timeoutPromise from "./timeoutPromise";

const retranslateEditor = async (editor: RetranslationEditor, from: string, to: string) => {
  const translate = GPTAPI.translateEditorText.bind(null, from, to);
  const editorText = {
    id: editor.id,
    text: editor.editorState.getCurrentContent().getPlainText()
  };
  const translation = await timeoutPromise(translate(editorText), 10000);

  return translation;
};

export default retranslateEditor;
