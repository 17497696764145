import _ from "lodash";
import { createContext, useContext, useEffect } from "react";
import { toast } from "react-toastify";

declare global {
  interface Window {
    data?: Data[];
    useXhrFields?: boolean;
    templateContext?: {
      userEmail: string;
      userDisplayName: string;
    };
    sourceName?: string;
  }
}

interface Data {
  displayName: string;
  entityTypeId: string;
  displayPicture: string;
  type: string;
  displayDescription: string;
  [key: string]: any;
}

type TemplateContext = {
  id: number;
  withCredentials: boolean;
  sourceName: string;
  entityTypeId: string;
  entityDisplayName: string;
  appMode: "Integrated" | "Standalone";
};

let templateData: TemplateContext = {
  withCredentials: false,
  id: 0,
  sourceName: "",
  entityTypeId: "",
  entityDisplayName: "",
  appMode: "Integrated"
};

export const templateContext = createContext<TemplateContext>({ ...templateData });
if (window.data && _.isArray(window.data)) {
  try {
    const data = window.data[0];
    templateData.entityTypeId = data.entityTypeId;
    templateData.entityDisplayName = data.displayName ? data.displayName : "";
    templateData.id = data.id;
    templateData.withCredentials = window.useXhrFields ? window.useXhrFields : false;
    templateData.sourceName = window.sourceName ? window.sourceName : "";
    templateData.appMode = "Integrated";
  } catch (err) {
    toast(`Error with template data`);
    console.log(err);
  }
} else if (window.location.search) {
  const urlParams = new URLSearchParams(window.location.search);
  templateData.entityTypeId = urlParams.get("entityTypeId") ?? "";
  templateData.entityDisplayName = urlParams.get("displayName") ?? "";
  templateData.id = parseInt(urlParams.get("entityId") ?? "0");
  templateData.sourceName = urlParams.get("sourceName") ?? "";
  templateData.appMode = "Standalone";
  templateData.withCredentials = false;
} else if (process.env.NODE_ENV === "development") {
  templateData.entityTypeId = process.env.REACT_APP_ENTITY_TYPE_ID ?? "Item";
  templateData.entityDisplayName = "Safir";
  templateData.id = process.env.REACT_APP_DEV_PRODUCT_ID
    ? parseInt(process.env.REACT_APP_DEV_PRODUCT_ID)
    : 177;
  templateData.sourceName = "InRiver";
  templateData.withCredentials = false;
  templateData.appMode = "Integrated";
  toast("App mode: Integrated, using development data");
} else {
  // App mode is standalone but no data is provided, this is the case
  // where we could let the user log in and then from a list select an entity to edit
  templateData.appMode = "Standalone";
}

export const useTemplateData = () => {
  return useContext(templateContext);
};

const InRiverTemplateDataProvider: React.FC = ({ children }) => {
  return <templateContext.Provider value={templateData}>{children}</templateContext.Provider>;
};

export default InRiverTemplateDataProvider;
