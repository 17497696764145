import { AppConfig } from "../static/config";

const getColumnWidths = (
  currentLocale: string,
  comparisonLocale: string,
  config: AppConfig
): number[] => {
  let mainWidth = 1;
  let comparisonWidth = 1;
  const { languageWidths } = config;
  if (languageWidths) {
    if (Object.prototype.hasOwnProperty.call(languageWidths, comparisonLocale)) {
      comparisonWidth = languageWidths[comparisonLocale];
    }
    if (Object.prototype.hasOwnProperty.call(languageWidths, currentLocale)) {
      mainWidth = languageWidths[currentLocale];
    }
  }
  const tot = mainWidth + comparisonWidth;
  return [mainWidth / tot, comparisonWidth / tot];
};

export default getColumnWidths;
