import axios from "axios";

const inRiverPost = (
  url: string,
  payload: string,
  config: any = {}
) => {
  const res = axios.post(`${process.env.REACT_APP_INRIVER_API_ENDPOINT}${url}`, payload, config);
  return res;
};

const inRiverGet = (
  url: string,
  config: any = {}
) => {
  const res = axios.get(`${process.env.REACT_APP_INRIVER_API_ENDPOINT}${url}`, config);
  return res;
};

const inRiverPut = (
  url: string,
  payload: any,
  config: any = {}
) => {
  const res = axios.put(`${process.env.REACT_APP_INRIVER_API_ENDPOINT}${url}`, payload, config);
  return res;
};

const InRiverAPI = {
  post: inRiverPost,
  get: inRiverGet,
  put: inRiverPut
};

export default InRiverAPI;
