import { Editor } from "../types";
import GPTAPI from "./GPTAPI";
import getEditorText from "./getEditorText";
import timeoutPromise from "./timeoutPromise";

const translateEditor = async (editor: Editor, from: string, to: string) => {
  const translate = GPTAPI.translateEditorText.bind(null, from, to);
  const editorText = getEditorText(from, editor);
  const translation = await timeoutPromise(translate(editorText), 10000);

  return translation;
};

export default translateEditor;
