import React, { useState, useEffect, useCallback } from "react";
import EntityApp from "./EntityApp";
import InRiverAPI from "../helpers/InRiverAPI";
import EntityLink from "../types/EntityLink";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import EntitySummary from "../types/EntitySummary";
import { useTemplateData } from "./contextProviders/InRiverTemplateDataProvider";
import { usePreviewStore } from "./contextProviders/PreviewStore";
import { GlobalAppStateAction, useGlobalState } from "./contextProviders/GlobalAppStateProvider";
import { useAppState } from "./contextProviders/AppStateProvider";
import { useInRiverAPIConfig } from "./contextProviders/InRiverAPIProvider";

type Props = {
  id: number;
};

export interface EntityData {
  id: number;
  entityTypeId: string;
  sourceName: string;
  entityDisplayName: string;
}

const includedRelationNames = [
  "ContentHolderArticleSection",
  "ArticleSectionArticleElement",
  "ProductArticleSection"
];

const AppMapper: React.FC<Props> = ({ id: parentId }) => {
  // Fetch entityChildren of ID
  // Filter based on type (or not for total mayhem)

  const inRiverAPIConfig = useInRiverAPIConfig();
  const { sourceName } = useTemplateData();

  const [childrenLinks, setChildrenLinks] = useState<EntityLink[]>([]);
  const [children, setChildren] = useState<EntityData[]>([]);

  const { addNode } = usePreviewStore();
  const { dispatch: globalDispatch } = useGlobalState();
  const { state } = useAppState();

  const currentDepth = state.currentDepth;

  const fetchEntityLinks = useCallback(async () => {
    try {
      const res: AxiosResponse<EntityLink[]> = await InRiverAPI.get(`/entities/${parentId}/links`, {
        ...inRiverAPIConfig,
        params: {
          linkDirection: "outbound"
        }
      });

      setChildrenLinks(res.data.filter((link) => includedRelationNames.includes(link.linkTypeId)));
    } catch (err) {
      toast.error("Couldn't related entities for + " + parentId);
      console.log("Error fetching fields data: ", err);
    }
  }, [parentId, inRiverAPIConfig]);

  const fetchEntityLinkData = useCallback(async () => {
    try {
      const promises: Promise<AxiosResponse<EntitySummary>>[] = [];

      childrenLinks.forEach((link) => {
        promises.push(
          InRiverAPI.get(`/entities/${link.targetEntityId}/summary`, inRiverAPIConfig) as Promise<
            AxiosResponse<EntitySummary>
          >
        );
      });

      const responses = await Promise.all(promises);

      const data = responses.map((res) => {
        const { id, entityTypeId, displayName: entityDisplayName } = res.data;
        return {
          id,
          entityTypeId,
          entityDisplayName,
          sourceName
        } as EntityData;
      });

      setChildren(data);
    } catch (err) {
      toast.error("Couldn't fetch data for some children");
      console.log("Error fetching data for entities: ", childrenLinks);
    }
  }, [childrenLinks, sourceName, inRiverAPIConfig]);

  useEffect(() => {
    fetchEntityLinks();
  }, [fetchEntityLinks]);

  useEffect(() => {
    fetchEntityLinkData();
  }, [fetchEntityLinkData]);

  // Add nodes to the preview state to update with content later.
  useEffect(() => {
    childrenLinks.forEach((link) =>
      addNode({
        entityId: link.targetEntityId,
        fields: [],
        comparisonFields: [],
        parentId: parentId,
        sortOrder: link.index
      })
    );
  }, [childrenLinks, addNode, parentId]);

  // Add savestates to the global state
  useEffect(() => {
    childrenLinks.forEach((link) =>
      globalDispatch({
        type: GlobalAppStateAction.addSaveState,
        payload: {
          id: link.targetEntityId,
          shouldSave: false
        }
      })
    );
  }, [globalDispatch, childrenLinks]);

  if (!children.length) return <></>;

  return (
    <div className="app-mapper">
      {children.map((child, index) => (
        <div className="app-mapper__inner-container" key={`${child.id}_${index}`}>
          <EntityApp currentDepth={currentDepth + 1} {...child} />
        </div>
      ))}
    </div>
  );
};

export default AppMapper;
