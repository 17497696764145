interface Input {
  entityId: number;
  authorEmail: string;
  authorName: string;
  fieldId: string;
  content: string;
  resolved: boolean;
  subject: string;
  quote: string;
  link: string;
}

const createCommentMutation = (input: Input) => {
  const { entityId, authorEmail, authorName, fieldId, content, resolved, subject, quote, link } =
    input;
  return `
  mutation{
    createComment(
    input:
    {
      entityID:${entityId},
      fieldId:"${fieldId}",
      quote:${JSON.stringify(quote)},
      content:${JSON.stringify(content)},
      resolved:${resolved},
      subject:"${subject}",
      authorName: "${authorName}",
      authorEmail:"${authorEmail}",
      link:"${link}",
    }){
      id,
      lastEdited,
      created, 
      authorName,
      authorEmail,
      fieldId,
      content,
      resolved, 
      subject, 
      quote,
      subcomments{
        nodes{
          id,
          lastEdited,
          created, 
          authorName,
          authorEmail,
          content,
          commentID,
        }
      }
    }
  }`;
};

export default createCommentMutation;
