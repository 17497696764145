import Joi from "joi";

const validate = (obj: any, fields: string[], formSchema: { [key: string]: any }) => {
  let subSchema: { [key: string]: any } = {};
  fields.forEach((field) => {
    if (Object.prototype.hasOwnProperty.call(formSchema, field)) {
      subSchema[field] = formSchema[field];
    } else {
      console.log(`Error in formvalidation: ${field} does not exist`);
    }
  });
  return Joi.object(subSchema).validate(obj);
};

export default validate;
