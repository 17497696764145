import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import LMSApi from "../../helpers/LexcionGqlAPI";
import { AxiosResponse } from "axios";
import { getUserQueryResponse, Permission } from "../../types";
import { getUserQuery } from "../../gql/queries";
import { useTemplateData } from "./InRiverTemplateDataProvider";

interface User {
  name: string;
  email: string;
  inRiverToken?: string;
  permissions: Permission[];
  canEditSteps: number[];
  isLoading: boolean;
  isLoadingSession: boolean;
  setUserCredentials: (email: string, password: string) => void;
}

const userContext = createContext<User>({
  name: "",
  email: "",
  inRiverToken: "",
  permissions: [],
  canEditSteps: [],
  isLoading: false,
  isLoadingSession: false,
  setUserCredentials: () => {}
});

export const useUser = () => {
  return useContext(userContext);
};

const userName = window.templateContext?.userDisplayName
  ? window.templateContext.userDisplayName
  : process.env.NODE_ENV === "development"
  ? "Pro Developer"
  : "";

const userEmail = window.templateContext?.userEmail
  ? window.templateContext.userEmail
  : process.env.NODE_ENV === "development"
  ? "viktor@kreationsbyran.se"
  : "";

const UserProvider: React.FC = ({ children }) => {
  const { appMode } = useTemplateData();

  // Credentials for when appMode is "Standalone"
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [user, setUser] = useState<
    Omit<User, "isLoading" | "isLoadingSession" | "setUserCredentials">
  >({
    name: userName,
    email: userEmail,
    inRiverToken: "",
    permissions: [],
    canEditSteps: []
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSession, setIsLoadingSession] = useState<boolean>(false);

  const setUserCredentials = (email: string, password: string) => {
    setEmail(email);
    setPassword(password);
  };

  useEffect(() => {
    if (user && user.email && user.permissions.length && !user.inRiverToken) {
      toast.error("No InRiver token found on user, please add it through the CMS!", {
        autoClose: false
      });
    }
  }, [user, user.email, user.permissions, user.inRiverToken]);

  useEffect(() => {
    const fetchUser = async (email: string, authKey?: string) =>
      LMSApi.post(getUserQuery(email), "en_GB", authKey);

    const handleFailedRequest = (err: any) => {
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      setEmail("");
      setPassword("");
      toast.error("Couldn't fetch LMS user and roles: " + err[0].message);
      console.log("Error fetching LMS role: ", err);
    };

    const throwIfGqlError = (res: AxiosResponse<getUserQueryResponse>) =>
      res.data.errors ? Promise.reject(res.data.errors) : res;

    const extractPermissions = (res: AxiosResponse<getUserQueryResponse>) => {
      const member = res.data.data.readOneMember;
      if (!member) return;

      const permissions = [
        ...member.permissions,
        ...member.groups.flatMap((group) => [
          ...group.permissions,
          ...group.roles.flatMap((role) => [...role.codes])
        ])
      ];

      const canEditSteps = member.groups.flatMap((group) =>
        group.assignedWorkflowSteps.map((step) => parseInt(step.id))
      );
      setUser((prev) => {
        return {
          ...prev,
          email: member.email,
          inRiverToken: member.inRiverToken,
          permissions,
          canEditSteps
        };
      });
    };

    if (appMode === "Standalone") {
      const existingToken = localStorage.getItem("token");
      const existingEmail = localStorage.getItem("email");
      if ((!email || !password || user.permissions.length) && (!existingToken || !existingEmail))
        return;
      const authKey = existingToken || "Basic " + btoa(`${email}:${password}`);
      localStorage.setItem("token", authKey);
      localStorage.setItem("email", existingEmail || email);

      existingToken && existingEmail ? setIsLoadingSession(true) : setIsLoading(true);

      // First check email and password
      fetchUser(existingEmail || email, authKey)
        .then(throwIfGqlError)
        .then(() =>
          // Fetch user again with admin api key to get permissions
          // because of a bug in Silverstripe
          // https://github.com/silverstripe/silverstripe-framework/issues/9580
          fetchUser(existingEmail || email)
            .then(throwIfGqlError)
            .then(extractPermissions)
            .catch(handleFailedRequest)
        )
        .catch(handleFailedRequest)
        .finally(() => {
          setIsLoading(false);
          setIsLoadingSession(false);
        });
    } else if (appMode === "Integrated") {
      if (!user.email || user.permissions.length) return;
      setIsLoading(true);
      fetchUser(user.email)
        .then(throwIfGqlError)
        .then(extractPermissions)
        .catch(handleFailedRequest)
        .finally(() => setIsLoading(false));
    }
  }, [appMode, email, password, user.email, user.permissions.length]);

  return (
    <userContext.Provider value={{ ...user, isLoading, isLoadingSession, setUserCredentials }}>
      {children}
    </userContext.Provider>
  );
};

export default UserProvider;
