export interface AppConfig {
  languageWidths?: {
    [key: string]: number;
  };
  commentMaxLength?: number;
}
export const commentMaxLength = 700; //Sets the maximum length of comments before being unable to send to server
export const commentMaxDisplayLength = 300; //Sets the max length of comment to be displayed by default
export const quoteMaxDisplayLength = 120; //Sets the max length of quotes to be displayed by default

//Sets the relative sizes of languages relative to each other
//English and swedish are approximately the same and used as baseline
//Setting a language size to 1.5 would mean that there is on average 50% more words/text mass than english/swedish
export const languageWidths = {
  sv_SE: 1,
  en_GB: 1
};

//Comment subjects to be available
export const availableSubjects = [
  "New Word",
  "Expand Definition",
  "Change Status",
  "Suggest Synonym",
  "Not a Synonym",
  "Incorrect Occurence",
  "Other"
];

//list of cvlIds to look for when determining product stages.
export const usedCvlIds = [
  "ProductStagesBase",
  "ProductStagesMedium",
  "ProductStagesPro",
  "ProductStagesCopywriter"
];

export const stageLocaleMapping: { [key: string]: string } = {
  StagesCompletedSv: "sv-SE",
  StagesCompletedEn: "en"
};

const config = {
  quoteMaxDisplayLength,
  commentMaxDisplayLength,
  languageWidths,
  usedCvlIds
};

export default config;
