import { LocaleObject } from "../types";

interface Props {
  locales: LocaleObject[];
  label?: string;
  currentLocale: string;
  excludeLocale?: string;
  closeBtnAction?: (arg: boolean) => void;
  setLocale: (locale: string) => void;
  selectName?: string;
  stripped?: boolean;
}

const LocaleSelector = (props: Props) => {
  const {
    locales,
    setLocale,
    currentLocale,
    excludeLocale,
    closeBtnAction,
    label,
    selectName,
    stripped = false
  } = props;
  // "any" type reasoning: could not find a suitable type...
  const handleChange = (e: any) => {
    const index = locales.findIndex((locale) => locale.locale === e.currentTarget.value);
    setLocale(index >= 0 ? locales[index].locale : currentLocale);
  };

  const filteredLocales = excludeLocale
    ? locales.filter((locale) => locale.locale !== excludeLocale)
    : locales;

  const getContainerClasses = () => {
    const classes = ["locale-selector"];
    if (!stripped) classes.push("locale-selector__full");
    return classes.join(" ");
  };

  return (
    <div className={getContainerClasses()}>
      <label className="locale-selector__label" htmlFor={selectName}>
        {label ? label : ""}
      </label>
      <select onChange={handleChange} value={currentLocale} name={selectName} id={selectName}>
        {locales.length &&
          filteredLocales.map((locale, index) => (
            <option key={index} value={locale.locale}>
              {locale.displayName}
            </option>
          ))}
      </select>
      {closeBtnAction && (
        <button onClick={() => closeBtnAction(false)} className="locale-selector__close-btn">
          Close
        </button>
      )}
    </div>
  );
};

export default LocaleSelector;
