import { Editor, EditorState } from "draft-js";
import { RetranslationEditor as RetranslationEditorType } from "../types";

type Props = {
  editor: RetranslationEditorType;
  locale: string;
  loading?: boolean;
  onChange: (state: EditorState) => void;
  onReTranslate: (editor: RetranslationEditorType) => void;
};

const RetranslationEditor: React.FC<Props> = ({
  editor,
  locale,
  loading = false,
  onChange,
  onReTranslate
}) => {
  return (
    <div className={`text-editor comparable ${loading ? "loading-translation" : ""}`}>
      <div className="comparable__item comparable__item--comparison"></div>
      <div className="comparable__item">
        <div className="retranslation-editor__header">
          <p className="retranslation-editor__label">Retranslation ({locale})</p>
          <span className="retranslation-editor__button" onClick={() => onReTranslate(editor)}>
            Use retranslation
          </span>
        </div>
        <div className="text-editor__field-container retranslation-editor__field-container">
          <Editor editorState={editor.editorState} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};

export default RetranslationEditor;
