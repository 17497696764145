import Joi from "joi";
import { ChangeEvent, FocusEvent, MouseEvent, useEffect, useState } from "react";
import SelectField from "../components/SelectField";
import validateFields from "../helpers/validate";
import { commentMaxLength } from "../static/config";
import { NewCommentInput } from "../types";
import Button from "./Button";
import TextArea from "./TextArea";

interface Props {
  onSubmit: (input: NewCommentInput) => void;
  fieldTypeId: string;
  quote: string;
}

const NewCommentForm = ({ fieldTypeId, onSubmit: submit, quote }: Props) => {
  const [fieldId, setFieldId] = useState<string>();
  const [fields, setFields] = useState({
    topic: "",
    comment: "",
  });
  const [errors, setErrors] = useState({
    topic: "",
    comment: "",
  });
  const [sent, setSent] = useState(false);

  const formSchema: any = {
    topic: Joi.string().min(1).required(),
    comment: Joi.string().min(1).max(commentMaxLength).required(),
  };

  useEffect(() => {
    if (!fieldId) {
      setFieldId(fieldTypeId);
    }
  }, [fieldTypeId, fieldId]);

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const fieldKeys = Object.keys(fields);
    const { error } = validateFields(fields, fieldKeys, formSchema);
    if (!error) {
      if (fieldId) {
        const input = { quote, subject: fields.topic, fieldId, content: fields.comment };
        submit(input);
        setSent(true);
      }
    } else {
      const label = error.details[0].context?.label;
      if (label) {
        setErrors({ ...errors, [label]: error.message });
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;
    const fieldName = e.currentTarget.name;

    validate(fieldName, value);
    setFields({ ...fields, [fieldName]: value });
  };

  const handleFocus = (e: FocusEvent<HTMLSelectElement> | FocusEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    const fieldName = e.currentTarget.name;
    validate(fieldName, value);
  };
  const handleBlur = (e: FocusEvent<HTMLSelectElement> | FocusEvent<HTMLTextAreaElement>) => {
    const fieldName = e.currentTarget.name;
    setErrors({ ...errors, [fieldName]: "" });
  };

  const validate = (fieldName: string, value: string) => {
    const { error } = validateFields({ [fieldName]: value }, [fieldName], formSchema);
    if (error) {
      setErrors({ ...errors, [fieldName]: error.message });
    } else {
      setErrors({ ...errors, [fieldName]: "" });
    }
  };

  return (
    <div className="new-comment">
      <h3 className="new-comment__title">New comment</h3>
      <form className="new-comment__form">
        {/* <label>Field: {fieldId}</label> */}
        <label className="new-comment__label">Quoted text</label>
        <blockquote className="new-comment__quote">{quote}</blockquote>
        <input type="hidden" id="comment-quote" name="comment-quote" value={quote} />

        {/* <input type="text" value={subject} onChange={(e) => setSubject(e.currentTarget.value)} /> */}
        <label className="new-comment__label" htmlFor="new-comment__topic">
          Topic
        </label>
        <SelectField
          selectFieldClass="new-comment__topic"
          onChange={handleChange}
          name="topic"
          value={fields.topic}
          error={errors.topic}
        />

        <label className="new-comment__label" htmlFor="comment-text">
          Comment
        </label>

        <div className="new-comment__text">
          <TextArea
            placeholder="Write your comment here"
            value={fields.comment}
            name="comment"
            onChange={handleChange}
            error={errors.comment}
            onBlur={handleBlur}
            onFocus={handleFocus}
            rows={3}
          >
            <div className="new-comment__character-count">
              {fields.comment.length + "/" + commentMaxLength}
            </div>
          </TextArea>
        </div>
        <Button onClick={handleSubmit} variant="primary" disabled={sent}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default NewCommentForm;
