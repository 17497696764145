const createEntityMutation = (entityId: number, modelId: number, displayName: string) => {
  return `
  mutation {
    createSourceEntity(input:{
      modelID:${modelId},
      externalId: ${entityId},
      title: ${JSON.stringify(displayName)}
    }){
      id,
      fields{
        id,
        fieldTypeId,
        fieldModel{
          workflowSteps{
            id
          }
        }
      }
      currentStep{
        id,
      }
    }
  }
  `;
};

export default createEntityMutation;
