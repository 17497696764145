import { useEffect, useState } from "react";

interface Props {
  label: string;
  onBack: () => void;
}

const ContextHeader = ({ label, onBack }: Props) => {
  const [stateLabel, setStateLabel] = useState<string>();
  useEffect(() => {
    if (label) {
      setStateLabel(label);
    }
  }, [label, stateLabel]);
  return (
    <div className="context-header">
      <button className="context-header__back-btn" onClick={onBack}>
        Back
      </button>
      <h2 className="context-header__title">{stateLabel ? stateLabel : label}</h2>
    </div>
  );
};

export default ContextHeader;
