import React from "react";
import ReactTooltip from "react-tooltip";
interface Props {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  iconActive: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  isActive: boolean;
  name: string;
  padding: string;
  disabled?: boolean;
  tooltip?: string;
  onClick?: (e: any) => void;
  element?: "div" | "button";
}

const OptionsItem: React.FC<Props> = ({
  icon: Icon,
  iconActive: IconActive,
  onClick,
  isActive,
  name,
  padding,
  disabled,
  tooltip,
  element = "button"
}) => {
  if (element === "button")
    return (
      <>
        <button
          id={name}
          disabled={disabled}
          className="options-item"
          onClick={onClick ? onClick : () => {}}
          data-tip={tooltip}
        >
          {isActive ? (
            <IconActive className="options-item__icon" style={{ padding: padding }} />
          ) : (
            <Icon className="options-item__icon" style={{ padding: padding }} />
          )}
        </button>
        <ReactTooltip
          delayShow={1000}
          backgroundColor="white"
          textColor="black"
          place="bottom"
          className="options-item__tooltip"
        />
      </>
    );
  return (
    <>
      <div
        id={name}
        className="options-item"
        onClick={onClick ? onClick : () => {}}
        data-tip={tooltip}
      >
        {isActive ? (
          <IconActive className="options-item__icon" style={{ padding: padding }} />
        ) : (
          <Icon className="options-item__icon" style={{ padding: padding }} />
        )}
      </div>
      <ReactTooltip
        delayShow={1000}
        backgroundColor="white"
        textColor="black"
        place="bottom"
        className="options-item__tooltip"
      />
    </>
  );
};

export default OptionsItem;
