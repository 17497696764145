import { PhraseMatch } from "kb-lexicon/dist/src/types/Interfaces";
import { useState } from "react";
import { Editor, LexiconComment, Panel, WarningLevel } from "../types";
import ContextHeader from "./ContextHeader";
import { AppStateAction, useAppState } from "./contextProviders/AppStateProvider";
import { useComments } from "./contextProviders/CommentsProvider";
import { useEditors } from "./contextProviders/EditorProvider";
import FieldCommentList from "./FieldCommentList";
import HitsFilter from "./HitsFilter";
import HitsList from "./HitsList";
import NewCommentForm from "./NewCommentForm";
import PhraseViewer from "./PhraseViewer";
import SidebarNavigation from "./SidebarNavigation";

interface Props {
  activePanel: Panel;
  activePhrase: PhraseMatch | null;
  comments: LexiconComment[];
  hitsList: PhraseMatch[];
  quote: string;
  setActiveEditor: (id: null | number) => void;
  setActivePhrase: (phraseMatch: PhraseMatch | null) => void;
  resetSelectionState: (editor: Editor) => void;
}

const Sidebar = (props: Props) => {
  const {
    hitsList,
    activePanel,
    setActiveEditor,
    setActivePhrase,
    comments,
    activePhrase,
    resetSelectionState,
    quote
  } = props;
  const Comments = useComments();
  const [hitsFilter, setHitsFilter] = useState<WarningLevel | null>(null);
  const { editors, activeEditorId, activeEditor } = useEditors();
  const AppState = useAppState();
  const getIssuesCounts = () => {
    const counts: { [key: string]: number } = {
      Established: 0,
      Warning: 0,
      Forbidden: 0,
      total: 0
    };
    hitsList.forEach((phraseMatch) => {
      phraseMatch.matches.forEach((match) => {
        counts[phraseMatch.phrase.warningLevel] += 1;
        counts["total"] += 1;
      });
    });
    return counts;
  };

  const getCommentsCount = () => {
    const counts = {
      resolved: 0,
      unresolved: 0
    };
    const resolvedComments = comments.filter((comment) => comment.resolved);
    counts.resolved = resolvedComments.length;
    counts.unresolved = comments.length - resolvedComments.length;
    return counts;
  };

  const getFilteredHits = () => {
    if (hitsFilter) return hitsList.filter((hit) => hit.phrase.warningLevel === hitsFilter);
    else return hitsList;
  };

  const getFilteredComments = () => {
    const filteredComments: {
      [key: string]: {
        comments: LexiconComment[];
        label: string;
      };
    } = {};
    if (!activeEditor) {
      editors.forEach(
        (editor) =>
          (filteredComments[editor.fieldTypeId] = {
            comments: [],
            label: editor.label
          })
      );
    } else {
      filteredComments[activeEditor.fieldTypeId] = {
        comments: [],
        label: activeEditor.label
      };
    }
    comments.forEach((comment) => {
      if (Object.keys(filteredComments).includes(comment.fieldId)) {
        filteredComments[comment.fieldId].comments.push(comment);
      }
    });
    return filteredComments;
  };

  const editorFieldId = activeEditor ? activeEditor.fieldTypeId : "";
  const editorLabel = activeEditor ? activeEditor.label : "";

  // If user has clicked add new comment, show new comment view above rest
  if (AppState.state.showNewComment)
    return (
      <aside className="sidebar">
        <ContextHeader
          label={editorLabel}
          onBack={() =>
            AppState.dispatch({ type: AppStateAction.setShowNewComment, payload: false })
          }
        />
        <>
          <div className="sidebar__container">
            {AppState.state.showNewComment && (
              <NewCommentForm
                fieldTypeId={editorFieldId}
                onSubmit={Comments.submit}
                quote={quote}
              />
            )}
          </div>
        </>
      </aside>
    );

  // If a phrase is active, show the phrase detail components.
  if (activePhrase)
    return (
      <aside className="sidebar">
        <>
          {activeEditorId !== null && (
            <ContextHeader label={editorLabel} onBack={() => setActivePhrase(null)} />
          )}
          <PhraseViewer
            activePanel={activePanel}
            setActivePhrase={setActivePhrase}
            activePhrase={activePhrase}
            activeEditor={activeEditorId}
          />
        </>
      </aside>
    );

  // If not, show the default lexicon issues / comments elements:
  return (
    <aside className="sidebar" onClick={() => activeEditor && resetSelectionState(activeEditor)}>
      {activeEditorId !== null && (
        <ContextHeader label={editorLabel} onBack={() => setActiveEditor(null)} />
      )}
      <div className="sidebar__container">
        <SidebarNavigation
          commentCount={getCommentsCount().unresolved}
          issueCount={getIssuesCounts().total}
        />
        {AppState.state.sidebarView === "issues" && !AppState.state.showNewComment && (
          <>
            <HitsFilter
              counts={getIssuesCounts()}
              setHitsFilter={setHitsFilter}
              hitsFilter={hitsFilter}
            />
            <HitsList hitsArray={getFilteredHits()} setActivePhrase={setActivePhrase} />
          </>
        )}

        {AppState.state.sidebarView === "comments" && !AppState.state.showNewComment && (
          <>
            <FieldCommentList
              fields={getFilteredComments()}
              resolveComment={Comments.resolve}
              deleteComment={Comments.delete}
              deleteSubcomment={Comments.deleteSub}
              submitNewSubcomment={Comments.submitSub}
            />
          </>
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
