import { ContentBlock } from "draft-js";
import { Phrase } from "kb-lexicon/dist/src/types/Interfaces";
import warningLevelClasses from "../static/warningLevelClasses";

const createDecorator = (phrase: Phrase, lexicon: any) => {
  const findWithRegex = (contentBlock: any, callback: any) => {
    const text = contentBlock.getText();
    const result = lexicon.searchForPhrase(phrase, text);
    result.hits.forEach((hit: any) => {
      callback(hit["index"], hit["index"] + hit[0].length);
    });
  };

  const handleStrategy = (contentBlock: ContentBlock, callback: any) => {
    findWithRegex(contentBlock, callback);
  };

  const SpanComponent = (props: any) => {
    const warningLevelClass =
      phrase.warningLevel === "Forbidden"
        ? warningLevelClasses.forbidden
        : phrase.warningLevel === "Warning"
        ? warningLevelClasses.warning
        : warningLevelClasses.consider;

    return (
      <span data-offset-key={props.offsetKey} className={"text-editor__hit " + warningLevelClass}>
        {props.children}
      </span>
    );
  };

  return { strategy: handleStrategy, component: SpanComponent };
};

export default createDecorator;
