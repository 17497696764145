import { FC, MouseEvent } from "react";

interface Props {
  onClick?: (() => void) | ((e: MouseEvent<HTMLButtonElement>) => void);
  className?: string;
  variant?: string;
  [key: string]: any;
}

const Button: FC<Props> = ({
  className: extraClasses,
  onClick: handleClick,
  children,
  variant,
  ...rest
}) => {
  return (
    <button className={`button button--${variant} ` + extraClasses} onClick={handleClick} {...rest}>
      {children}
    </button>
  );
};

export default Button;
