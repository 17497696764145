import { boolean } from "joi";
import React, { createContext, ReactNode, useContext, useReducer } from "react";

type Props = {
  children: ReactNode;
};

type State = {
  isLoading: boolean;
  showAllFields: boolean;
  showComparison: boolean;
  showPreview: boolean;
  activeEntityId: number;
  saveStates: {
    [key: number]: boolean;
  };
};

export enum GlobalAppStateAction {
  setIsLoading,
  setShowAllFields,
  setShowComparison,
  setShowPreview,
  addSaveState,
  updateSaveState,
  resetSaveStates,
  setActiveEntityId
}

type Action =
  | {
      type:
        | GlobalAppStateAction.setIsLoading
        | GlobalAppStateAction.setShowAllFields
        | GlobalAppStateAction.setShowComparison
        | GlobalAppStateAction.setShowPreview;
      payload: boolean;
    }
  | {
      type: GlobalAppStateAction.setActiveEntityId;
      payload: number;
    }
  | {
      type: GlobalAppStateAction.updateSaveState | GlobalAppStateAction.addSaveState;
      payload: {
        shouldSave: boolean;
        id: number;
      };
    }
  | {
      type: GlobalAppStateAction.resetSaveStates;
    };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case GlobalAppStateAction.setIsLoading:
      return {
        ...state,
        isLoading: action.payload
      };
    case GlobalAppStateAction.setShowAllFields:
      return {
        ...state,
        showAllFields: action.payload
      };
    case GlobalAppStateAction.setShowComparison:
      return {
        ...state,
        showComparison: action.payload
      };
    case GlobalAppStateAction.setShowPreview:
      return {
        ...state,
        showPreview: action.payload
      };
    case GlobalAppStateAction.updateSaveState:
      return {
        ...state,
        saveStates: {
          ...state.saveStates,
          [action.payload.id]: action.payload.shouldSave
        }
      };
    case GlobalAppStateAction.addSaveState:
      return {
        ...state,
        saveStates: {
          ...state.saveStates,
          [action.payload.id]: action.payload.shouldSave
        }
      };

    case GlobalAppStateAction.setActiveEntityId:
      return {
        ...state,
        activeEntityId: action.payload
      };
    case GlobalAppStateAction.resetSaveStates: {
      const newSaveStates: { [key: number]: boolean } = Object.keys(state.saveStates).reduce(
        (acc, cur) => {
          return { ...acc, [parseInt(cur)]: true };
        },
        {}
      );
      return {
        ...state,
        saveStates: newSaveStates
      };
    }
  }
};

const initialState = {
  isLoading: false,
  showAllFields: false,
  showComparison: false,
  showPreview: false,
  saveStates: {},
  activeEntityId: 0
};

export type GlobalAppState = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

const globalAppStateContext = createContext<GlobalAppState>({
  state: initialState,
  dispatch: (value: Action) => {}
});

export const useGlobalState = () => {
  return useContext(globalAppStateContext);
};

const GlobalAppStateProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <globalAppStateContext.Provider value={{ state, dispatch }}>
      {children}
    </globalAppStateContext.Provider>
  );
};

export default GlobalAppStateProvider;
