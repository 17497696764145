import { useState } from "react";
import { commentMaxDisplayLength, quoteMaxDisplayLength } from "../static/config";
import { LexiconComment, SubcommentInput } from "../types";
import ReplyForm from "./ReplyForm";
import SubcommentList from "./SubcommentList";

interface Props {
  comment: LexiconComment;
  resolveComment: (id: number, status: boolean) => void;
  deleteComment: (id: number) => void;
  deleteSubcomment: (id: number) => void;
  submitNewSubcomment: (input: SubcommentInput) => void;
}

const CommentItem = (props: Props) => {
  const { comment, resolveComment, deleteComment, submitNewSubcomment, deleteSubcomment } = props;
  const [showSubcomments, setShowSubcomments] = useState(false);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [showWholeQuote, setShowWholeQuote] = useState(false);
  const [showWholeComment, setShowWholeComment] = useState(false);
  const handleSubmitNewSubcomment = (input: SubcommentInput) => {
    submitNewSubcomment(input);
  };

  var replyLabel = comment.subcomments.nodes.length > 1 ? "replies" : "reply";

  const getComment = () => {
    return showWholeComment ||
      showReplyForm ||
      showSubcomments ||
      comment.content.length < commentMaxDisplayLength
      ? comment.content
      : `${comment.content.substr(0, commentMaxDisplayLength)}...`;
  };

  const getQuote = () => {
    return showWholeQuote || comment.quote.length < quoteMaxDisplayLength
      ? comment.quote
      : `${comment.quote.substr(0, quoteMaxDisplayLength)}...`;
  };

  const toggleShowQuote = () => {
    showWholeQuote ? setShowWholeQuote(false) : setShowWholeQuote(true);
  };
  const toggleShowComment = () => {
    showWholeComment ? setShowWholeComment(false) : setShowWholeComment(true);
  };

  const quoteClasses = () => {
    let classes = ["comment-item__quote"];
    if (comment.quote.length > quoteMaxDisplayLength)
      classes.push("comment-item__quote--expandable");
    if (showWholeQuote) classes.push("comment-item__quote--expanded");
    return classes.join(" ");
  };

  const commentClasses = () => {
    let classes = ["comment-item__comment"];
    if (comment.content.length > commentMaxDisplayLength)
      classes.push("comment-item__comment--expandable");
    if (showWholeComment) classes.push("comment-item__comment--expanded");
    return classes.join(" ");
  };

  const showConfirmDialog = (callback: () => void, message: string) => {
    if (window.confirm(message)) {
      callback();
    }
  };

  return (
    <div className="comment-item">
      <blockquote className={quoteClasses()} onClick={toggleShowQuote}>
        {getQuote()}
      </blockquote>
      <div className="comment-item__initials">
        {comment.authorName
          .split(" ")
          .map((n) => n[0])
          .join("")}
      </div>
      <div className="comment-item__container">
        <div className="comment-item__meta-container">
          <div className="comment-item__author">{comment.authorName}</div>
          <div className="comment-item__date-time">{comment.created}</div>
        </div>
        <div className="comment-item__actions-container">
          {!comment.resolved ? (
            <button
              className="comment-item__toggle-resolve"
              onClick={() =>
                showConfirmDialog(
                  () => resolveComment(comment.id, true),
                  "Are you sure you wish to resolve this comment?"
                )
              }
            >
              Resolve
            </button>
          ) : (
            <button
              className="comment-item__toggle-resolve"
              onClick={() => resolveComment(comment.id, false)}
            >
              Unresolve
            </button>
          )}
          <button
            className="comment-item__delete"
            onClick={() =>
              showConfirmDialog(
                () => deleteComment(comment.id),
                "Are you sure you wish to delete this comment?"
              )
            }
          >
            Delete
          </button>
        </div>
        <div className={commentClasses()} onClick={toggleShowComment}>
          {getComment()}
        </div>
        {comment.subcomments.nodes.length > 0 ? (
          showSubcomments ? (
            <button
              className="comment-item__toggle-replies"
              onClick={() => {
                setShowSubcomments(false);
                setShowReplyForm(false);
              }}
            >
              Hide {comment.subcomments.nodes.length} {replyLabel}
            </button>
          ) : (
            <button
              className="comment-item__toggle-replies"
              onClick={() => {
                setShowSubcomments(true);
                setShowReplyForm(true);
              }}
            >
              Show {comment.subcomments.nodes.length} {replyLabel}
            </button>
          )
        ) : showReplyForm ? (
          <button
            className="comment-item__toggle-replies"
            onClick={() => {
              setShowReplyForm(false);
            }}
          >
            Cancel
          </button>
        ) : (
          <button className="comment-item__toggle-replies" onClick={() => setShowReplyForm(true)}>
            Reply
          </button>
        )}

        {comment.subcomments.nodes.length > 0 && showSubcomments && (
          // If subcomments exist, show subcomments feed
          <div className="comment-item__subcomments-container">
            <SubcommentList
              subcomments={comment.subcomments.nodes}
              deleteSubcomment={deleteSubcomment}
            />
          </div>
        )}
      </div>
      {showReplyForm && (
        <ReplyForm
          commentID={comment.id}
          submitNewSubcomment={handleSubmitNewSubcomment}
          setShowSubcomments={setShowSubcomments}
        />
      )}
    </div>
  );
};

export default CommentItem;
