import axios from "axios";

const defaultAuthKey = process.env.REACT_APP_LEXICON_AUTH_HEADER ?? "";

const postLexiconQuery = (query: string, locale: string = "en_GB", authKey: string = defaultAuthKey) => {
  return axios({
    url: `${process.env.REACT_APP_LEXICON_API_ENDPOINT}?l=${locale}`,
    method: "post",
    headers: {
      Authorization: authKey,
    },
    data: {
      query: query,
    },
  });
};

const LexiconGqlAPI = {
  post: postLexiconQuery,
};

export default LexiconGqlAPI;
