import "./styles/main.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AuthGate from "./components/AuthGate";
import InRiverTemplateDataProvider from "./components/contextProviders/InRiverTemplateDataProvider";
import UserProvider from "./components/contextProviders/UserProvider";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/">
            <InRiverTemplateDataProvider>
              <UserProvider>
                <AuthGate />
              </UserProvider>
            </InRiverTemplateDataProvider>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
export default App;
