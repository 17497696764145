import React from "react";
import Login from "./Login";
import OuterApp from "./contextProviders/OuterApp";
import OptionsBar from "./organisms/OptionsBar";
import PreviewModal from "./PreviewModal";
import SaveButton from "./SaveButton";
import { useTemplateData } from "./contextProviders/InRiverTemplateDataProvider";
import { useUser } from "./contextProviders/UserProvider";
import OuterContextsProvider from "./contextProviders/OuterContextsProvider";

const AuthGate = () => {
  const { appMode, entityTypeId } = useTemplateData();
  const { permissions, isLoadingSession } = useUser();

  if (appMode === "Standalone" && !entityTypeId) {
    return (
      <div>No entity data provided, to edit an entity navigate via the CMS links instead!</div>
    );
  } else if (appMode === "Standalone" && !permissions.length) {
    return !isLoadingSession ? <Login /> : null;
  } else {
    return (
      <React.Fragment>
        <OuterContextsProvider>
          <SaveButton />
          <PreviewModal />
          <OptionsBar />
          <OuterApp />
        </OuterContextsProvider>
      </React.Fragment>
    );
  }
};

export default AuthGate;
