const getUserQuery = (email: string) => {
  return `
  query{
    readOneMember(filter:{
      email: {
        eq: ${JSON.stringify(email)}
      }
    }){
      email
      inRiverToken
      permissions{
        id,
        code
      }
      groups{
        id,
        title,
        permissions{
          id,
          code
        }
        assignedWorkflowSteps{
          id,
          title
        }
        roles{
          codes{
            id,
            code,
          }
        }
      }
    }
  }
  `;
};

export default getUserQuery;
