import { useEffect, useState } from "react";
import { AppStateAction, useAppState } from "./contextProviders/AppStateProvider";

import { useEntity } from "./contextProviders/EntityProvider";
import { useLocales } from "./contextProviders/LocaleProvider";
import VersionSelector from "./VersionSelector";
import getColumnWidths from "../helpers/getColumnWidths";
import config from "../static/config";

interface Props {
  triggerTranslation?: () => void;
}

const VersionSelectors: React.FC<Props> = ({ triggerTranslation }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { state: appState, dispatch: appStateDispatch } = useAppState();
  const { currentLocale, comparisonLocale } = useLocales();
  const [mainWidth, comparisonWidth] = getColumnWidths(currentLocale, comparisonLocale, config);

  const entity = useEntity();
  const handleVersionClick = () => {
    appStateDispatch({
      type: AppStateAction.setShowVersion,
      payload: appState.showVersion !== 0 ? 0 : entity.versions[0].id
    });
    if (isActive) {
      appStateDispatch({
        type: AppStateAction.setShowComparisonVersion,
        payload: 0
      });
    }
    setIsActive(!isActive);
  };

  useEffect(() => {
    setIsActive(false);
  }, [comparisonLocale]);

  return (
    <div className="version-selectors">
      <div
        className={`version-selectors__comparison comparable__item comparable__item--comparison`}
        style={{
          flex: `${comparisonWidth} 1 0px`
        }}
      >
        <button className="version-selectors__toggle-versions-button" onClick={handleVersionClick}>
          {isActive ? "Close" : "Compare versions"}
        </button>

        {isActive && (
          <>
            <VersionSelector
              versionId={appState.showVersion}
              setVersion={(id: number) =>
                appStateDispatch({ type: AppStateAction.setShowVersion, payload: id })
              }
            />
          </>
        )}

        {/* NOTE: Keeping this in case of rollback */}
        {/* {appState.isEditable && (
          <button className="version-selectors__translation-button" onClick={triggerTranslation}>
            Translate to {currentLocale}
          </button>
        )} */}
      </div>
      <div
        className={`comparable__item comparable__item--comparison`}
        style={{
          flex: `${mainWidth} 1 0px`
        }}
      >
        {isActive && (
          <VersionSelector
            versionId={appState.showComparisonVersion}
            setVersion={(id: number) =>
              appStateDispatch({ type: AppStateAction.setShowComparisonVersion, payload: id })
            }
            replacementText="Current"
          ></VersionSelector>
        )}
      </div>
    </div>
  );
};

export default VersionSelectors;
