const resolveCommentMutation = (id: number, status: boolean) => {
  return `
  mutation{
    updateComment(input:{id:${id}, resolved:${status}}){
      id
    }
  }
  `;
};
export default resolveCommentMutation;
