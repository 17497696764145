const getLocalesQuery = () => {
  return `
    query{
      readLocales{
        title,
        locale, 
        inriverLocaleCode
      }
    }
  `;
};

export default getLocalesQuery;
