import { useEffect } from "react";
import { AppStateAction, useAppState } from "../components/contextProviders/AppStateProvider";
import { useEditors } from "../components/contextProviders/EditorProvider";
import {
  GlobalAppStateAction,
  useGlobalState
} from "../components/contextProviders/GlobalAppStateProvider";
import { useID } from "../components/contextProviders/IDProvider";
import usePreviewUpdates from "./usePreviewUpdates";

const useAppEffects = () => {
  const { state: appState, dispatch: appDispatch } = useAppState();
  const { state: globalState, dispatch: globalDispatch } = useGlobalState();
  const { save } = useEditors();
  const { id } = useID();

  const shouldSave = globalState.saveStates[id];

  // Resets the version state when hiding comparison view
  useEffect(() => {
    if (!globalState.showComparison && !!appState.showVersion) {
      appDispatch({ type: AppStateAction.setShowVersion, payload: 0 });
    }
  }, [appDispatch, appState.showVersion, globalState.showComparison]);

  useEffect(() => {
    if (shouldSave && !appState.isSaving) {

      save({
        onAfter: () =>
          globalDispatch({
            type: GlobalAppStateAction.updateSaveState,
            payload: {
              id,
              shouldSave: false
            }
          })
      });
    }
  }, [shouldSave, save, appState.isSaving, globalDispatch, id]);

  usePreviewUpdates();
};

export default useAppEffects;
