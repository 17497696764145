const getVersionsQuery = (entityId: number, limit: number, offset: number) => {
  return `
  query{
    readEntityVersions(
      filter:{
        entityID: {
          eq: ${entityId},
        }
      }
      sort:{
        created: DESC
      }
      limit: ${limit}
      offset: ${offset}
    ){
      nodes{
        id,
        created,
        currentWorkflowStep{
          title,
          id,
        }
        fieldVersions{
          text
          field{
            fieldTypeId
          }
        }
      }
      pageInfo{
        totalCount
        hasNextPage,
        hasPreviousPage,
      }
    }
  }
  `;
};

export default getVersionsQuery;
