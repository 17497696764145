import { ContentState, Editor as DraftEditor, EditorState } from "draft-js";
import { Panel, Editor, RetranslationEditor as RetranslationEditorType } from "../types";
import { AppStateAction, useAppState } from "./contextProviders/AppStateProvider";
import { useEditors } from "./contextProviders/EditorProvider";
import { useGlobalState } from "./contextProviders/GlobalAppStateProvider";
import { useLocales } from "./contextProviders/LocaleProvider";
import MessageDisplay from "./MessageDisplay";
import TextEditor from "./TextEditor";
import VersionSelectors from "./VersionSelectors";
import { useEntity } from "./contextProviders/EntityProvider";
import { useState } from "react";
import useRetranslations from "../hooks/useRetranslations";
import RetranslationEditor from "./RetranslationEditor";
import translateEditors from "../helpers/translateEditors";
import translateEditor from "../helpers/translateEditor";
import getEditorText from "../helpers/getEditorText";
import editorTextNotEmpty from "../helpers/editorTextNotEmpty";
import retranslateEditor from "../helpers/retranslateEditor";

interface Props {
  handleChange: (newState: EditorState, editorId: number, panel: Panel, locale: string) => void;
  handleEditorFocus: (id: null | number, panel: "main" | "comparison") => void;
  setQuote: (input: string) => void;
  onTranslation: (editors: Editor[]) => void;
}

const MainContainer = (props: Props) => {
  let { handleChange, handleEditorFocus, setQuote, onTranslation } = props;

  const {
    editors,
    hasLoaded: editorsLoaded,
    activePanel,
    activeEditorId: activeEditor,
    filter: editorFilter
  } = useEditors();
  const { state: appState, dispatch } = useAppState();
  const GlobalState = useGlobalState();
  const { currentLocale, comparisonLocale } = useLocales();
  const { versions } = useEntity();
  const {
    editors: retranslationEditors,
    setEditor: setRestranslationEditor,
    setEditorState: setRetranslationEditorState
  } = useRetranslations();

  const [transLoading, setTransLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<number[]>([]);
  const [fieldLoading, setFieldLoading] = useState<number[]>([]);

  const getFilteredEditors = () => {
    const fullFilter = [...editorFilter]; // Filters editors based on workflow filter and the lmsStage field from inRiver;

    return GlobalState.state.showAllFields
      ? editors
      : editors.filter((editor) => !fullFilter.includes(editor.fieldTypeId));
  };
  const getContainerStyles = () => {
    return {
      marginLeft: `-${appState.currentDepth * 30}px`
    };
  };

  const translateFields = async () => {
    setTransLoading(true);
    setFieldErrors([]);

    // Translate all comparison editors to current locale and then back to comparison locale
    translateEditors(getFilteredEditors(), comparisonLocale, currentLocale).then(
      ([translations, retranslations]) => {
        // Update retranslation editors
        retranslations.forEach((res) => {
          setRestranslationEditor(res.value.id, res.value.text);
        });

        // Update current locale editors
        const newEditors = [...editors].map((editor) => {
          const result = translations.find((res) => res.value.id === editor.id);
          return result
            ? {
                ...editor,
                editorStates: {
                  ...editor.editorStates,
                  [currentLocale]: EditorState.createWithContent(
                    ContentState.createFromText(result.value.text)
                  )
                }
              }
            : editor;
        });
        onTranslation(newEditors);

        // Editors that was not translated probably timed out due to long
        // GPT response time. These editors will be marked with an error message.
        const failedEditorIds = [...editors]
          .map((editor) => getEditorText(comparisonLocale, editor))
          .filter(editorTextNotEmpty)
          .filter((editor) => !translations.find((res) => res.value.id === editor.id))
          .map((editor) => editor.id);
        setFieldErrors(failedEditorIds);

        setTransLoading(false);
      }
    );
  };

  const translateField = async (editor: Editor) => {
    setFieldLoading((fieldIds) => [...fieldIds, editor.id]);
    const { id, text } = await translateEditor(editor, comparisonLocale, currentLocale);

    const newEditors = [...editors].map((editor) => {
      if (editor.id === id) {
        return {
          ...editor,
          editorStates: {
            ...editor.editorStates,
            [currentLocale]: EditorState.createWithContent(ContentState.createFromText(text))
          }
        };
      } else {
        return editor;
      }
    });
    onTranslation(newEditors);
    setFieldLoading((fieldIds) => fieldIds.filter((fieldId) => fieldId !== editor.id));
  };

  const reTranslateField = async (editor: Editor) => {
    setFieldLoading((fieldIds) => [...fieldIds, editor.id]);
    setRestranslationEditor(editor.id, "");
    const { id, text } = await translateEditor(editor, currentLocale, comparisonLocale);
    setRestranslationEditor(id, text);
    setFieldLoading((fieldIds) => fieldIds.filter((fieldId) => fieldId !== editor.id));
  };

  const onRetranslation = async (editor: RetranslationEditorType) => {
    setFieldLoading((fieldIds) => [...fieldIds, editor.id]);
    const { id, text } = await retranslateEditor(editor, comparisonLocale, currentLocale);
    const newEditors = [...editors].map((editor) => {
      if (editor.id === id) {
        return {
          ...editor,
          editorStates: {
            ...editor.editorStates,
            [currentLocale]: EditorState.createWithContent(ContentState.createFromText(text))
          }
        };
      } else {
        return editor;
      }
    });
    onTranslation(newEditors);
    setFieldLoading((fieldIds) => fieldIds.filter((fieldId) => fieldId !== editor.id));
  };

  return (
    <>
      <section
        className={`main-container ${
          GlobalState.state.showComparison ? "main-container--comparison" : ""
        }`}
        style={getContainerStyles()}
      >
        <div className={`${transLoading ? "loading-translation" : ""}`}>
          {transLoading && <div className="loading-icon"></div>}
          {GlobalState.state.showComparison && versions && versions.length > 0 && (
            <VersionSelectors triggerTranslation={translateFields} />
          )}

          <MessageDisplay
            active={appState.showMessage}
            onClose={() => dispatch({ type: AppStateAction.setShowMessage, payload: false })}
          />

          {editorsLoaded &&
            getFilteredEditors().map((editor) => {
              const retranslationEditor = retranslationEditors.find((e) => e.id === editor.id);
              const loading = fieldLoading.includes(editor.id);

              return (
                <div>
                  <TextEditor
                    activePanel={activePanel}
                    activeEditor={activeEditor}
                    currentLocale={currentLocale}
                    comparisonLocale={comparisonLocale}
                    errorMessage={
                      fieldErrors.includes(editor.id)
                        ? "Something went wrong with the translation, please try again!"
                        : ""
                    }
                    editor={editor}
                    handleChange={handleChange}
                    handleEditorFocus={handleEditorFocus}
                    key={editor.id}
                    loading={loading}
                    setQuote={setQuote}
                    onTranslate={translateField}
                    onReTranslate={reTranslateField}
                  />

                  {retranslationEditor && (
                    <RetranslationEditor
                      editor={retranslationEditor}
                      locale={comparisonLocale}
                      loading={loading}
                      onChange={(state) => setRetranslationEditorState(editor.id, state)}
                      onReTranslate={onRetranslation}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </section>
    </>
  );
};

export default MainContainer;
