import { Subcomment } from "../types";
import SubcommentItem from "./SubcommentItem";

interface Props {
  subcomments: Subcomment[];
  deleteSubcomment: (id: number) => void;
}

const SubcommentList = ({ subcomments, deleteSubcomment }: Props) => {
  return (
    <>
      {subcomments.length > 0 &&
        subcomments.map((subcomment) => (
          <SubcommentItem
            subcomment={subcomment}
            key={subcomment.id}
            deleteSubcomment={deleteSubcomment}
          />
        ))}
    </>
  );
};

export default SubcommentList;
