import { useState } from "react";
import RetranslationEditor from "../types/RetranslationEditor";
import { ContentState, EditorState } from "draft-js";

const useRetranslations = () => {
  const [editors, setEditors] = useState<RetranslationEditor[]>([]);

  const setEditor = (id: number, text: string) => {
    setEditors((editors) => {
      const previousEditor = editors.find((editor) => editor.id === id);
      if (previousEditor) {
        return editors.map((editor) => {
          if (editor.id === id) {
            return {
              id,
              editorState: EditorState.createWithContent(ContentState.createFromText(text))
            };
          }
          return editor;
        });
      } else {
        return [
          ...editors,
          {
            id,
            editorState: EditorState.createWithContent(ContentState.createFromText(text))
          }
        ];
      }
    });
  };

  const setEditorState = (id: number, editorState: EditorState) => {
    setEditors((editors) =>
      editors.map((editor) => ({
        ...editor,
        editorState: editor.id === id ? editorState : editor.editorState
      }))
    );
  };

  return {
    editors,
    setEditor,
    setEditorState
  };
};

export default useRetranslations;
