import { createContext, FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getLocalesQuery } from "../../gql/queries";
import InRiverAPI from "../../helpers/InRiverAPI";
import LMSApi from "../../helpers/LexcionGqlAPI";
import { LMSLocale, LocaleObject } from "../../types";
import { useInRiverAPIConfig } from "./InRiverAPIProvider";

interface LocaleContext {
  currentLocale: string;
  setCurrentLocale: (locale: string) => void;
  comparisonLocale: string;
  setComparisonLocale: (locale: string) => void;
  localeMap: { [key: string]: string };
  availableLocales: LocaleObject[];
  isLoading: boolean;
}
export const localeContext = createContext<LocaleContext>({
  currentLocale: "en",
  setCurrentLocale: (locale) => {},
  comparisonLocale: "sv-SE",
  setComparisonLocale: (locale) => {},
  localeMap: {},
  availableLocales: [],
  isLoading: true
});

interface Props {}

export const useLocales = () => {
  return useContext(localeContext);
};

const LocaleProvider: FC<Props> = ({ children }) => {
  const inRiverAPIConfig = useInRiverAPIConfig();
  const [currentLocale, setCurrentLocale] = useState<string>("en_GB");
  const [comparisonLocale, setComparisonLocale] = useState<string>("sv_SE");
  const [availableLocales, setAvailableLocales] = useState<LocaleObject[]>([]);
  const [lmsLocales, setLmsLocales] = useState<LMSLocale[]>([]);
  const [inRiverLocales, setInRiverLocales] = useState<any[]>([]);
  const [localeMap, setLocaleMap] = useState<{ [key: string]: any }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchLocales = async () => {
      try {
        const res = await InRiverAPI.get(`/model/languages`, inRiverAPIConfig);
        if (!res.data.errors) {
          setInRiverLocales(res.data);
          return res.data;
        }
      } catch (err) {
        toast.error("Couldn't fetch InRiver locales");
        console.log("Error fetching locales: ", err);
      }
    };

    const fetchLMSLocales = async () => {
      try {
        const { data } = await LMSApi.post(getLocalesQuery());
        if (!data.errors) {
          const locales: LMSLocale[] = data.data.readLocales;
          setLmsLocales(locales);
        } else {
          toast.error("Unable to fetch LMS Locales");
          console.log("Error fetching lms locales: ", data.errors);
        }
      } catch (err) {}
    };
    setIsLoading(true);
    const promises = [fetchLocales(), fetchLMSLocales()];
    Promise.all(promises).then(() => setIsLoading(false));
  }, [inRiverAPIConfig]);

  useEffect(() => {
    if (inRiverLocales && lmsLocales) {
      const newLocaleMap: { [key: string]: string } = {};
      const availableLocales: LocaleObject[] = [];
      lmsLocales.forEach((locale) => {
        const existsInInRiver = inRiverLocales.find(
          (inRiverLocale: any) => inRiverLocale.name === locale.inriverLocaleCode
        );
        if (locale.inriverLocaleCode && locale.locale && existsInInRiver) {
          newLocaleMap[locale.inriverLocaleCode] = locale.locale;
          availableLocales.push({
            displayName: locale.title,
            locale: locale.locale,
            sourceLocale: locale.inriverLocaleCode
          });
        }
      });
      setAvailableLocales(availableLocales);
      setLocaleMap(newLocaleMap);
    }
  }, [inRiverLocales, lmsLocales]);

  const contextValue = {
    currentLocale,
    setCurrentLocale,
    comparisonLocale,
    setComparisonLocale,
    availableLocales,
    isLoading,
    localeMap
  };
  return <localeContext.Provider value={contextValue}>{children}</localeContext.Provider>;
};

export default LocaleProvider;
