import { CompositeDecorator, EditorState } from "draft-js";
import { Phrase } from "kb-lexicon/dist/src/types/Interfaces";
import { Editor } from "../types";
import createDecorator from "./createDecorator";

const addDecorators = (editors: Editor[], lexicon: any, locale: string) => {
  const phrases = lexicon.getPhrases(locale);
  let decoratorArray: any = [];
  phrases.forEach((phrase: Phrase) => {
    decoratorArray.push(createDecorator(phrase, lexicon));
  });
  const decorator = new CompositeDecorator(decoratorArray);

  const editorsCopy = [...editors];
  editorsCopy.forEach((editor) => {
    editor.editorStates[locale] = EditorState.createWithContent(
      editor.editorStates[locale].getCurrentContent(),
      decorator
    );
  });
  return editorsCopy;
};

export default addDecorators;
