const createVersionMutation = (entityId: number, workflowStepId: number) => {
  return `
  mutation{
    createEntityVersion(input: {
      entityID: ${entityId},
      currentWorkflowStepID: ${workflowStepId}
    }){
      id,
      created, 
      currentWorkflowStep{
        title,
        id,
      }
    }
  }
  `;
};

export default createVersionMutation;
