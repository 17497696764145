import React, { createContext, useContext, useEffect, useReducer } from "react";
import { SidebarView } from "../../types";

interface State {
  showVersion: number;
  showComparisonVersion: number;

  showNewComment: boolean;
  sidebarView: SidebarView;
  isLoading: boolean;
  isEditable: boolean;
  showMessage: boolean;
  lmsStageField: string;
  isSaving: boolean;
  currentDepth: number;
}

export enum AppStateAction {
  setShowVersion,
  setShowComparisonVersion,
  setShowNewComment,
  setSidebarView,
  setIsLoading,
  setIsEditable,
  setShowMessage,
  setLmsStageField,
  setIsSaving,
  setCurrentDepth
}

type Action =
  | {
      type:
        | AppStateAction.setShowVersion
        | AppStateAction.setShowComparisonVersion
        | AppStateAction.setCurrentDepth;
      payload: number;
    }
  | {
      type:
        | AppStateAction.setShowNewComment
        | AppStateAction.setIsLoading
        | AppStateAction.setIsEditable
        | AppStateAction.setIsSaving
        | AppStateAction.setShowMessage;

      payload: boolean;
    }
  | {
      type: AppStateAction.setSidebarView;
      payload: SidebarView;
    }
  | {
      type: AppStateAction.setLmsStageField;
      payload: string;
    };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case AppStateAction.setShowVersion:
      return {
        ...state,
        showVersion: action.payload
      };
    case AppStateAction.setShowComparisonVersion:
      return {
        ...state,
        showComparisonVersion: action.payload
      };
    case AppStateAction.setShowNewComment:
      return {
        ...state,
        showNewComment: action.payload
      };
    case AppStateAction.setSidebarView:
      return {
        ...state,
        sidebarView: action.payload
      };
    case AppStateAction.setIsLoading:
      return {
        ...state,
        isLoading: action.payload
      };
    case AppStateAction.setIsEditable:
      return {
        ...state,
        isEditable: action.payload,
        showMessage: !action.payload
      };
    case AppStateAction.setShowMessage:
      return {
        ...state,
        showMessage: action.payload
      };
    case AppStateAction.setLmsStageField:
      return {
        ...state,
        lmsStageField: action.payload
      };
    case AppStateAction.setIsSaving:
      return {
        ...state,
        isSaving: action.payload
      };
    case AppStateAction.setCurrentDepth:
      return {
        ...state,
        currentDepth: action.payload
      };
    default:
      return state;
  }
};

const initialState: State = {
  showVersion: 0,
  showComparisonVersion: 0,
  showNewComment: false,
  sidebarView: "issues",
  isLoading: true,
  isEditable: false,
  showMessage: false,
  lmsStageField: "",
  isSaving: false,
  currentDepth: 0
};

interface AppState {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const appStateContext = createContext<AppState>({
  state: initialState,
  dispatch: (value: Action) => {}
});

export const useAppState = () => {
  return useContext(appStateContext);
};

type Props = {
  currentDepth: number;
};

const AppStateProvider: React.FC<Props> = ({ children, currentDepth }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: AppStateAction.setCurrentDepth, payload: currentDepth });
  }, [currentDepth]);

  return (
    <appStateContext.Provider value={{ state, dispatch }}>{children}</appStateContext.Provider>
  );
};

export default AppStateProvider;
