import { ChangeEvent } from "react";
import { availableSubjects } from "../static/config";
// todo: accept props for options data + select name/id, and replace hard-coded values
interface Props {
  selectFieldClass?: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  name: string;
  error: string;
}

const SelectField = ({ selectFieldClass, onChange: handleChange, name, value, error }: Props) => {
  // TODO: Implement these topics in lexicon.
  return (
    <>
      <div className={"select-field " + selectFieldClass}>
        <select
          name={name}
          value={value ? value : "default-option"}
          id={name}
          onChange={handleChange}
        >
          <option value="default-option" disabled>
            Select a topic
          </option>
          {availableSubjects.map((subject) => (
            <option value={subject} key={subject}>
              {subject}
            </option>
          ))}
        </select>
      </div>
      {error && (
        <span className="select-field__error-message">
          <strong>Just a heads up:</strong> {error}
        </span>
      )}
      <div className="select-field__margin-spacer" />
    </>
  );
};

export default SelectField;
