const updateStepMutation = (entityId: number, stageId: number) => {
  return `
  mutation {
    updateSourceEntity(input:{
      id:${entityId},
      currentStepID:${stageId},
    }){
      id,
      currentStepID
    }
  }
  `;
};

export default updateStepMutation;
