import { createContext, useContext } from "react";
import { useTemplateData } from "./InRiverTemplateDataProvider";
import { useUser } from "./UserProvider";

const INRIVER_API_KEY_API_USER = process.env.REACT_APP_INRIVER_API_KEY ?? "";

type InRiverAPIContext = {
  withCredentials: boolean;
  params: { [key: string]: any };
  headers: { [key: string]: any };
};

const defaultContext: InRiverAPIContext = {
  withCredentials: false,
  params: {},
  headers: {
    "accept-language": "en"
  }
};

export const InRiverAPIContext = createContext<InRiverAPIContext>(defaultContext);

export const useInRiverAPIConfig = () => useContext(InRiverAPIContext);

/**
 * This component provides the basic InRiver API configuration to the rest of the app.
 * If in standalone mode and logged in it uses the user API key and otherwise it will
 * use the standard API user key set in the environment variables.
 */
const InRiverAPIProvider: React.FC = ({ children }) => {
  const { withCredentials, appMode } = useTemplateData();
  const { inRiverToken } = useUser();

  const apiKey = appMode === "Standalone" ? inRiverToken : INRIVER_API_KEY_API_USER;
  const headers =
    process.env.NODE_ENV === "development" || appMode === "Standalone"
      ? {
          ...defaultContext.headers,
          "X-inRiver-APIKey": apiKey
        }
      : defaultContext.headers;

  return (
    <InRiverAPIContext.Provider
      value={{
        ...defaultContext,
        withCredentials,
        headers
      }}
    >
      {children}
    </InRiverAPIContext.Provider>
  );
};

export default InRiverAPIProvider;
