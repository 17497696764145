import React from "react";
import { ReactComponent as CloseIcon } from "../img/icon-close-black.svg";
interface Props {
  onClose: () => void;
  active: boolean;
}

const MessageDisplay: React.FC<Props> = ({ onClose, active }) => {
  return (
    <>
      {active && (
        <div className="message-display">
          <div className="message-display__close-icon">
            <CloseIcon onClick={onClose} />
          </div>
          <div>You don't have permissions to edit this stage.</div>
        </div>
      )}
    </>
  );
};

export default MessageDisplay;
