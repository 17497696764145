import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { useUser } from "./contextProviders/UserProvider";

function Login() {
  const { setUserCredentials, isLoading } = useUser();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setUserCredentials(email, password);
  };

  return (
    <form onSubmit={handleSubmit} className="login">
      <p className="login__title">Tentipi LMS</p>
      <div className="login__field">
        <label htmlFor="email" className="login__label">
          Email:
        </label>
        <div className="login__input-container">
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            className="login__input"
            required
          />
        </div>
      </div>
      <div className="login__field">
        <label htmlFor="password" className="login__label">
          Password:
        </label>
        <div className="login__input-container">
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            className="login__input"
            required
          />
        </div>
      </div>
      <button type="submit" className="login__button" disabled={isLoading}>
        Log In
      </button>
    </form>
  );
}

export default Login;
