import React, { useContext } from "react";

interface IDContext {
  id: number;
  entityTypeId: string;
  sourceName: string;
  entityDisplayName: string;
}

interface Props {
  id: number;
  entityTypeId: string;
  sourceName: string;
  entityDisplayName: string;
}

const IdContext = React.createContext<IDContext>({
  id: 0,
  entityTypeId: "",
  sourceName: "",
  entityDisplayName: ""
});

export const useID = () => useContext(IdContext);

const IDProvider: React.FC<Props> = (props) => {
  return <IdContext.Provider value={{ ...props }}>{props.children}</IdContext.Provider>;
};

export default IDProvider;
