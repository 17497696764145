import React from "react";

interface Props {
  [key: string]: any;
}

const VersionsList: React.FC<Props> = ({ children, ...rest }) => {
  return <ul {...rest}>{children}</ul>;
};

export default VersionsList;
