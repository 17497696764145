interface Input {
  authorEmail: string;
  authorName: string;
  content: string;
  commentID: number;
}

const createSubcommentMutation = (input: Input) => {
  const { authorEmail, authorName, content, commentID } = input;
  return `
  mutation{
    createSubcomment(input:{
      authorEmail: ${JSON.stringify(authorEmail)}
      authorName: ${JSON.stringify(authorName)}
      content: ${JSON.stringify(content)}
      commentID: ${commentID}
    })
    {
      id
      commentID
      content
      authorEmail
      authorName
      lastEdited
      created
    }
  }`;
};

export default createSubcommentMutation;
