import { createContext, FC, useContext } from "react";
import { AppConfig } from "../../static/config";

interface Props {
  value: AppConfig;
}

export const configContext = createContext<AppConfig>({
  commentMaxLength: 700,
  languageWidths: {},
});

export const useConfig = () => {
  return useContext(configContext);
};

const ConfigProvider: FC<Props> = ({ children, value }) => {
  return <configContext.Provider value={value}>{children}</configContext.Provider>;
};

export default ConfigProvider;
