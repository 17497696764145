import { useEffect, useState } from "react";
import { useEditors } from "../components/contextProviders/EditorProvider";
import { useGlobalState } from "../components/contextProviders/GlobalAppStateProvider";
import { useID } from "../components/contextProviders/IDProvider";
import { useLocales } from "../components/contextProviders/LocaleProvider";
import { Field, HeadingLevel, TagType } from "../components/contextProviders/PreviewStore";
import { usePreviewStore } from "../components/contextProviders/PreviewStore";
import { Editor } from "../types";

const entityTypeIdsWithHeadingAndTitle = [
  "ContentHolder",
  "ArticleSection",
  "ArticleElement",
  "Feature"
];

// This method should preferably not be used at all, but instead use LMS filters.
const fieldIncludedInPreview = (fieldTypeId: string): boolean => {
  return true;
};

const getHeadingType = (fieldTypeId: string): HeadingLevel => {
  if (fieldTypeId.toLocaleLowerCase().includes("element")) return "h3";
  if (fieldTypeId.toLocaleLowerCase().includes("section")) return "h2";
  return "h1";
};

const getElementForFieldTypeId = (fieldTypeId: string, entityTypeId: string): TagType => {
  const lcFieldId = fieldTypeId.toLocaleLowerCase();
  if (entityTypeIdsWithHeadingAndTitle.includes(entityTypeId)) {
    if (lcFieldId.includes("head")) return getHeadingType(fieldTypeId);
  } else {
    if (lcFieldId.includes("title") || lcFieldId.includes("name"))
      return getHeadingType(fieldTypeId);
  }
  return "p";
};

const mapEditorStateToField = (
  editor: Editor,
  locale: string,
  entityTypeId: string,
  filter: string[]
): Field => {
  return {
    fieldTypeId: editor.fieldTypeId,
    element: getElementForFieldTypeId(editor.fieldTypeId, entityTypeId),
    content: editor.editorStates[locale]?.getCurrentContent().getPlainText() ?? "",
    isFiltered: filter.includes(editor.fieldTypeId)
  };
};

const usePreviewUpdates = () => {
  const { state: globalState } = useGlobalState();
  const updateNode = usePreviewStore((state) => state.updateNode);
  const editors = useEditors();
  const { currentLocale, comparisonLocale } = useLocales();
  const { id: entityId, entityTypeId } = useID();
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const { filter } = useEditors();

  useEffect(() => {
    if (!globalState.showPreview) return;
    setShouldUpdate(true);
  }, [globalState.showPreview, currentLocale, comparisonLocale]);

  useEffect(() => {
    if (!shouldUpdate) return;
    const includedFields = editors.editors.filter((editor) =>
      fieldIncludedInPreview(editor.fieldTypeId)
    );

    const fields = includedFields.map((editor) =>
      mapEditorStateToField(editor, currentLocale, entityTypeId, filter)
    );
    const comparisonFields = includedFields.map((editor) =>
      mapEditorStateToField(editor, comparisonLocale, entityTypeId, filter)
    );

    updateNode(entityId, fields, comparisonFields);
    setShouldUpdate(false);
  }, [
    editors,
    shouldUpdate,
    updateNode,
    entityId,
    currentLocale,
    entityTypeId,
    filter,
    comparisonLocale
  ]);
};

export default usePreviewUpdates;
