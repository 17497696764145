import { AxiosResponse } from "axios";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  createEntityMutation,
  createFieldVersionMutation,
  createVersionMutation,
  updateStepMutation
} from "../../gql/mutations";
import { getEntityQuery, getModelQuery, getVersionsQuery } from "../../gql/queries";
import InRiverAPI from "../../helpers/InRiverAPI";
import LMSApi from "../../helpers/LexcionGqlAPI";
import {
  Editor,
  EntityField,
  EntityVersion,
  FieldVersion,
  LMSEntityField,
  LMSEntityVersion,
  Workflow,
  WorkflowStep,
  LMSFieldModel
} from "../../types";
import { useAppState } from "./AppStateProvider";
import { useID } from "./IDProvider";
import { useTemplateData } from "./InRiverTemplateDataProvider";
import { useLocales } from "./LocaleProvider";
import { useUser } from "./UserProvider";
import { useInRiverAPIConfig } from "./InRiverAPIProvider";

interface EntityContext {
  id: number;
  LMSId: number;
  LMSFieldModels: LMSFieldModel[];
  fieldSet: string;
  entityTypeDisplayName: string;
  fields: EntityField[];
  versions: EntityVersion[];
  versionsInfo: {
    count: number;
    hasNextPage: boolean;
    pageSize: number;
    loadedPages: number;
  };
  createVersion: (editors: Editor[], locale: string, workflowStepId: number) => Promise<void>;
  getNextVersionsPage: () => void;
}

export const entityContext = createContext<EntityContext>({
  id: 0,
  LMSId: 0,
  LMSFieldModels: [],
  fields: [],
  fieldSet: "",
  versions: [],
  entityTypeDisplayName: "",
  versionsInfo: {
    count: 0,
    hasNextPage: false,
    pageSize: 0,
    loadedPages: 0
  },
  createVersion: async (editors: Editor[], locale: string, workflowStepId: number) => {
    return;
  },
  getNextVersionsPage: () => {}
});

const emptyWorkflow = {
  id: 0,
  currentStep: 0,
  steps: [],
  nextStepId: 0,
  prevStepId: 0,
  nextStepTitle: "",
  prevStepTitle: "",
  nextStepOrder: 0,
  prevStepOrder: 0,
  completeStep: () => {},
  undoStep: () => {},
  reset: () => {},
  set: (stepId: number) => {}
};

const workflowContext = createContext<Workflow>(emptyWorkflow);

export const useEntity = () => {
  return useContext(entityContext);
};

export const useWorkflow = () => {
  return useContext(workflowContext);
};

const handleVersionsQuery = (entityVersions: LMSEntityVersion[]) => {
  const versions: EntityVersion[] = [];
  entityVersions.forEach((entityVersion) => {
    const fieldVersions: FieldVersion[] = [];
    entityVersion.fieldVersions.forEach((fieldVersion) => {
      fieldVersions.push({
        text: fieldVersion.text,
        fieldTypeId: fieldVersion.field.fieldTypeId
      });
    });
    versions.push({
      id: parseInt(entityVersion.id),
      step: {
        ...entityVersion.currentWorkflowStep,
        id: parseInt(entityVersion.currentWorkflowStep.id)
      },
      created: entityVersion.created,
      fieldVersions
    });
  });
  return versions;
};

const versionsLimit = 10;

const EntityProvider: React.FC = ({ children }) => {
  const inRiverAPIConfig = useInRiverAPIConfig();
  const { withCredentials, appMode } = useTemplateData();
  const { inRiverToken } = useUser();
  const { id: entityId, entityTypeId, sourceName, entityDisplayName } = useID();
  const { currentLocale, comparisonLocale } = useLocales();
  const { state } = useAppState();
  const { availableLocales } = useLocales();
  const [entityModelId, setEntityModelId] = useState<number | null>(null);
  const [workflow, setWorkflow] = useState<Omit<Workflow, "currentStep">>(emptyWorkflow);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [LMSId, setLMSId] = useState<number>(0);
  const [comparisonId, setComparisonId] = useState<number>(0);
  const [fields, setFields] = useState<EntityField[]>([]);
  const [versions, setVersions] = useState<EntityVersion[]>([]);
  const [loadedPages, setLoadedPages] = useState<number>(0);
  const [versionsCount, setVersionsCount] = useState<number>(0);
  const [hasNextVersionsPage, setHasNextVersionsPage] = useState<boolean>(false);
  const [fieldSet, setFieldSet] = useState<string>("");
  const [fieldModels, setFieldModels] = useState<LMSFieldModel[]>([]);
  const [entityTypeDisplayName, setEntityTypeDisplayName] = useState<string>("");

  //To understand why all this fetching is being done you need to understand the datamodel for the LMS
  //1. We need the entityModelId to make sure we are looking for entities in the right source and for the workflows steps
  //2. We need the EntityID in the LMS (LMSId) to fetch current step and to add comments etc.

  //Fetches the EntityModelID from LMS

  useEffect(() => {
    if (sourceName) {
      const getModelId = async (sourceName: string, entityTypeId: string) => {
        const { data } = await LMSApi.post(getModelQuery(sourceName, entityTypeId));
        if (!data.errors) {
          const res = data.data?.readOneEntityModel;

          if (!res) {
            toast.error("Error fetching entity model, no response given");
            return;
          }

          // Update model id
          if (res.id) {
            setEntityModelId(parseInt(data?.data?.readOneEntityModel?.id));
          } else {
            toast.error("Error fetching entity model");
          }

          // Update model fields
          if (res.fieldModels && res.fieldModels.length) {
            setFieldModels(
              res.fieldModels.map((model: { id: string; typeId: string }) => {
                return { ...model, id: parseInt(model.id) };
              })
            );
          }
          // Update workflow
          if (res.workflow.id && parseInt(res.workflow.id)) {
            const steps = res.workflow.steps.map((step: any) => {
              return {
                id: parseInt(step.id),
                order: parseInt(step.sortOrder),
                title: step.title
              };
            });

            setWorkflow((prev) => {
              return {
                ...prev,
                id: parseInt(res.workflow.id),
                steps: steps
              };
            });
          } else {
            toast.error("No workflow found");
          }
        } else {
          toast.error("Unable to fetch LMS data");
          console.log("Error fetching model: ", data.errors);
        }
      };
      getModelId(sourceName, entityTypeId);
    }
  }, [sourceName, entityTypeId]);

  const createLMSEntity = useCallback(
    async (entityId: number, modelId: number, locale: string) => {
      const { data } = await LMSApi.post(
        createEntityMutation(entityId, modelId, entityDisplayName),
        locale
      );
      const entity = data.data?.createSourceEntity;
      if (!data.errors) {
        return entity;
      } else {
        toast.error("Unable to fetch LMS data");
        console.log("Error creating entity: ", data.errors);
      }
    },
    [entityDisplayName]
  );

  const getLMSEntity = useCallback(
    async (entityId: number, modelId: number, locale: string) => {
      const { data } = await LMSApi.post(getEntityQuery(entityId, modelId), locale);
      const entity = data.data?.readOneSourceEntity;

      if (entity && entity.id) {
        return entity;
      } else if (!data.errors) {
        return createLMSEntity(entityId, modelId, locale);
      } else {
        toast.error("Unable to fetch LMS data");
        console.log("Error fetching entity from LMS: ", data.errors);
      }
    },
    [createLMSEntity]
  );

  //Fetches the SourceEntityID from LMS or creates a new entity if it doesn't exist yet.
  // Should be rewritten to o
  useEffect(() => {
    if (entityId && entityModelId) {
      const findOrCreateEntity = async () => {
        const entity = await getLMSEntity(entityId, entityModelId, currentLocale);

        setLMSId(parseInt(entity.id));
        setCurrentStep(parseInt(entity.currentStep.id));
        setFields(
          entity.fields.map((field: LMSEntityField) => {
            return {
              id: parseInt(field.id),
              fieldTypeId: field.fieldTypeId,
              workflowSteps: field.fieldModel.workflowSteps.map((step) => {
                return {
                  id: parseInt(step.id)
                };
              })
            };
          })
        );
      };
      findOrCreateEntity();
    }
  }, [entityId, entityModelId, currentLocale, getLMSEntity]);

  useEffect(() => {
    const fetchFieldSet = async () => {
      try {
        const { data: summary } = await InRiverAPI.get(
          `/entities/${entityId}/summary`,
          inRiverAPIConfig
        );

        setEntityTypeDisplayName(summary.entityTypeDisplayName);
        setFieldSet(summary.fieldSetId);
      } catch (ex) {
        toast.error("Couldn't fetch entity fieldset");
      }
    };
    if (entityId) fetchFieldSet();
  }, [entityId, inRiverAPIConfig]);

  //Fetches the SourceEntityID from LMS or creates a new entity if it doesn't exist yet.
  useEffect(() => {
    if (entityId && entityModelId) {
      const findOrCreateEntity = async () => {
        const entity = await getLMSEntity(entityId, entityModelId, comparisonLocale);
        setComparisonId(parseInt(entity.id));
      };
      findOrCreateEntity();
    }
  }, [entityId, entityModelId, comparisonLocale, getLMSEntity]);

  useEffect(() => {
    setLoadedPages(0);
  }, [comparisonLocale]);

  useEffect(() => {
    if (comparisonId && comparisonLocale) {
      const fetchVersions = async () => {
        const { data } = await LMSApi.post(
          getVersionsQuery(comparisonId, versionsLimit, 0),
          comparisonLocale
        );
        if (data.errors) {
          toast.error("Couldn't fetch old versions");
          console.log("Error fetching old versions: ", data.errors);
        } else if (data.data.readEntityVersions) {
          setVersionsCount(data.data.readEntityVersions.pageInfo.totalCount);
          setHasNextVersionsPage(data.data.readEntityVersions.pageInfo.hasNextPage);
          const entityVersions: LMSEntityVersion[] = data.data.readEntityVersions.nodes;
          if (entityVersions.length > 0) {
            const tmpVersions = handleVersionsQuery(entityVersions);
            setVersions(tmpVersions);
          }
        }
      };
      fetchVersions();
    }
  }, [comparisonId, comparisonLocale]);

  //Updates next/prevStage when currentStage is updated
  useEffect(() => {
    const currentIndex = workflow.steps.findIndex((step) => step.id === currentStep);
    if (currentIndex > -1) {
      const nextStep =
        currentIndex + 1 < workflow.steps.length ? workflow.steps[currentIndex + 1].id : 0;
      const prevStep = currentIndex - 1 >= 0 ? workflow.steps[currentIndex - 1].id : 0;
      setWorkflow((prev) => {
        return {
          ...prev,
          nextStepId: nextStep,
          prevStepId: prevStep
        };
      });
    }
  }, [currentStep, workflow.steps]);

  const createVersion = async (editors: Editor[], locale: string, workflowStepId: number) => {
    let { data } = await LMSApi.post(createVersionMutation(LMSId, workflowStepId), locale);
    if (!data.errors) {
      const versionId = parseInt(data.data.createEntityVersion.id);
      const res = await createFieldVersions(versionId, editors, locale);
      if (res && locale === comparisonLocale && res.data.fieldVersions.length > 0) {
        const workflowStep = workflow.steps.find((step) => step.id == workflowStepId);
        const stepName = workflowStep ? workflowStep.title : "";
        setVersions((prev) => [
          {
            id: versionId,
            created: new Date().toLocaleString(),
            step: {
              id: workflowStepId,
              title: stepName
            },
            fieldVersions: res.data.fieldVersions
          },
          ...prev
        ]);
      }
    } else {
      toast.error("Failed saving new version");
      console.log("Error saving new version: ", data.error);
    }
  };

  const createFieldVersions = async (
    entityVersionId: number,
    editors: Editor[],
    locale: string
  ) => {
    const promises: Promise<AxiosResponse<any>>[] = [];
    editors.forEach((editor) => {
      const text = editor.editorStates[locale].getCurrentContent().getPlainText();
      const field = fields.find((field) => field.fieldTypeId === editor.fieldTypeId);
      if (field) {
        promises.push(
          LMSApi.post(createFieldVersionMutation(text, entityVersionId, field.id), locale)
        );
      }
    });
    const res = await Promise.all(promises)
      .then((responses) => {
        const errors: any = [];
        const fieldVersions: FieldVersion[] = [];
        if (!responses) return;
        responses.forEach((res) => {
          const data = res.data;
          if (data.errors) {
            errors.push(errors);
          } else {
            const fieldVersion = data.data.createFieldVersion;
            fieldVersions.push({
              text: fieldVersion.text,
              fieldTypeId: fieldVersion.field.fieldTypeId
            });
          }
        });
        if (!errors.length) {
          toast.success("Saved new version");
        } else {
          toast.error("Failed saving new versions");
          console.log("Errors saving new version: ", errors);
        }
        const data = {
          data: {
            fieldVersions
          },
          errors
        };
        return data;
      })
      .catch((err) => {
        toast.error("Failed saving new versions");
        console.log("Errors saving new version: ", err);
        const data: { data: { fieldVersions: FieldVersion[] }; errors: any } = {
          data: {
            fieldVersions: []
          },
          errors: err
        };
        return data;
      });
    return res;
  };

  const getNextVersionsPage = async () => {
    if (hasNextVersionsPage) {
      const { data } = await LMSApi.post(
        getVersionsQuery(comparisonId, versionsLimit, (loadedPages + 1) * versionsLimit),
        comparisonLocale
      );
      if (data.errors) {
        toast.error("Couldn't fetch old versions");
        console.log("Error fetching old versions: ", data.errors);
      } else if (data.data.readEntityVersions) {
        setVersionsCount(data.data.readEntityVersions.pageInfo.totalCount);
        setHasNextVersionsPage(data.data.readEntityVersions.pageInfo.hasNextPage);
        const entityVersions: LMSEntityVersion[] = data.data.readEntityVersions.nodes;
        const tmpVersions = handleVersionsQuery(entityVersions);
        setLoadedPages(loadedPages + 1);
        setVersions((prev) => [...prev, ...tmpVersions]);
      }
    }
  };

  const completeStep = async () => {
    const steps = workflow.steps;
    const currentStepIndex = steps.findIndex(
      (workflowStep: WorkflowStep) => workflowStep.id === currentStep
    );
    if (currentStepIndex >= 0 && currentStepIndex + 1 < steps.length) {
      const nextStep = steps[currentStepIndex + 1].id;

      setCurrentStep(nextStep);
      const { data } = await LMSApi.post(updateStepMutation(LMSId, nextStep), currentLocale);
      if (data.errors) {
        toast.error("Couldn't update workflowstep");
        console.log("Error updating workflow", data.error);
        setCurrentStep(currentStep);
      } else if (currentStepIndex + 2 === steps.length) {
        setInRiverStage(currentLocale, "Completed");
      }
    }
  };

  const undoStep = async () => {
    const steps = workflow.steps;
    const currentStepIndex = steps.findIndex(
      (workflowStep: WorkflowStep) => workflowStep.id === currentStep
    );
    if (currentStepIndex > 0) {
      const prevStep = steps[currentStepIndex - 1].id;
      setCurrentStep(prevStep);
      const { data } = await LMSApi.post(updateStepMutation(LMSId, prevStep), currentLocale);
      if (data.errors) {
        toast.error("Couldn't update workflowstep");
        console.log("Error updating workflow", data.error);
        setCurrentStep(currentStep);
      } else {
        setInRiverStage(currentLocale, "In Progress");
      }
    }
  };

  const reset = async () => {
    const steps = workflow.steps;
    if (steps.length) {
      const firstStep = steps[0].id;
      setCurrentStep(firstStep);
      const { data } = await LMSApi.post(updateStepMutation(LMSId, firstStep), currentLocale);
      if (data.errors) {
        toast.error("Couldn't update workflowstep");
        console.log("Error updating workflow", data.error);
        setCurrentStep(currentStep);
      } else {
        setInRiverStage(currentLocale, "In Progress");
      }
    }
  };

  const set = async (stepId: number) => {
    setCurrentStep(stepId);
    const { data } = await LMSApi.post(updateStepMutation(LMSId, stepId), currentLocale);
    if (data.errors) {
      toast.error("Couldn't update workflowstep");
      console.log("Error updating workflow", data.error);
      setCurrentStep(currentStep);
    }
  };

  const setInRiverStage = async (locale: string, stage: string) => {
    try {
      const inRiverLocale = availableLocales.find(
        (localeObject) => localeObject.locale === locale
      )?.sourceLocale;

      const payload = [
        {
          fieldTypeId: state.lmsStageField,
          value: {
            [inRiverLocale ?? ""]: stage
          }
        }
      ];

      const { data } = await InRiverAPI.put(
        `/entities/${entityId}/fieldValues`,
        payload,
        inRiverAPIConfig
      );
      if (data) {
        toast.success("Updated LMS Stage");
      }
    } catch (ex) {
      toast.error("Couldn't set LMS Stage in InRiver");
      console.log("Error updating LMS Stage", ex);
    }
  };

  const prevStepTitle =
    workflow.nextStepId === 0
      ? workflow.steps[0]?.title
      : workflow.steps.find((step) => step.id === workflow.prevStepId)?.title ?? "";

  const nextStepTitle = workflow.steps.find((step) => step.id === workflow.nextStepId)?.title ?? "";

  const prevStepOrder =
    workflow.nextStepId === 0
      ? 1
      : workflow.steps.find((step) => step.id === workflow.prevStepId)?.order ?? 0;

  const nextStepOrder = workflow.steps.find((step) => step.id === workflow.nextStepId)?.order ?? 0;

  return (
    <entityContext.Provider
      value={{
        id: entityId,
        LMSId,
        LMSFieldModels: fieldModels,
        entityTypeDisplayName,
        fields,
        fieldSet,
        createVersion,
        versions,
        versionsInfo: {
          hasNextPage: hasNextVersionsPage,
          count: versionsCount,
          pageSize: versionsLimit,
          loadedPages
        },
        getNextVersionsPage
      }}
    >
      <workflowContext.Provider
        value={{
          ...workflow,
          currentStep,
          completeStep,
          undoStep,
          reset,
          set,
          nextStepTitle,
          prevStepTitle,
          nextStepOrder,
          prevStepOrder
        }}
      >
        {children}
      </workflowContext.Provider>
    </entityContext.Provider>
  );
};

export default EntityProvider;
