import { WarningLevel } from "../types";

interface Props {
  counts: {
    [key: string]: number;
  };
  setHitsFilter: (warningLevel: WarningLevel | null) => void;
  hitsFilter: WarningLevel | null;
}

const HitsFilter = ({ counts, setHitsFilter, hitsFilter }: Props) => {
  return (
    <div className="hits-filter">
      <button
        className={`hits-filter__item hits-filter__item--all ${
          !hitsFilter ? "hits-filter__item--active" : ""
        }`}
        onClick={() => setHitsFilter(null)}
      >
        {counts.total} <span>All phrases</span>
      </button>
      <button
        className={`hits-filter__item hits-filter__item--established ${
          hitsFilter === "Established" ? "hits-filter__item--active" : ""
        }`}
        onClick={() => setHitsFilter("Established")}
      >
        {counts.Established} <span>Established</span>
      </button>
      <button
        className={`hits-filter__item hits-filter__item--warning ${
          hitsFilter === "Warning" ? "hits-filter__item--active" : ""
        }`}
        onClick={() => setHitsFilter("Warning")}
      >
        {counts.Warning} <span>Warnings</span>
      </button>
      <button
        className={`hits-filter__item hits-filter__item--forbidden ${
          hitsFilter === "Forbidden" ? "hits-filter__item--active" : ""
        }`}
        onClick={() => setHitsFilter("Forbidden")}
      >
        {counts.Forbidden} <span>Forbidden</span>
      </button>
    </div>
  );
};

export default HitsFilter;
