import { Subcomment } from "../types";

interface Props {
  subcomment: Subcomment;
  deleteSubcomment: (id: number) => void;
}

const SubcommentItem = ({ subcomment, deleteSubcomment }: Props) => {
  return (
    <div className="comment-item">
      <div className="comment-item__initials">
        {subcomment.authorName
          .split(" ")
          .map((n) => n[0])
          .join("")}
      </div>
      <div className="comment-item__container">
        <div className="comment-item__meta-container">
          <div className="comment-item__author">{subcomment.authorName}</div>
          <div className="comment-item__date-time">{subcomment.lastEdited}</div>
        </div>
        <div className="comment-item__actions-container">
          <button className="comment-item__delete" onClick={() => deleteSubcomment(subcomment.id)}>
            Delete
          </button>
        </div>
        <div className="comment-item__comment">{subcomment.content}</div>
      </div>
    </div>
  );
};

export default SubcommentItem;
