const getModelQuery = (sourceName: string, typeId: string) => {
  return `
  query{
    readOneEntityModel(filter: {
      typeId: {
        eq: ${JSON.stringify(typeId)},
      }
      source:{
        title: {
          eq: ${JSON.stringify(sourceName)},
        }
      }
    }){
      id,
      fieldModels{
        id,
        typeId,
      }
      workflow{
        id,
        title,
        description,
        steps(sort:{
          sortOrder: ASC
        }){
          id,
          title,
          sortOrder,
        }
      }

    }
  }
  `;
};

export default getModelQuery;
