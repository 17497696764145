import Joi from "joi";
import { ChangeEvent, SyntheticEvent, useState } from "react";
import validate from "../helpers/validate";
import { commentMaxLength } from "../static/config";
import { SubcommentInput } from "../types";
import TextArea from "./TextArea";

interface Props {
  submitNewSubcomment: (input: SubcommentInput) => void;
  setShowSubcomments: (input: boolean) => void;
  commentID: number;
}

const formSchema = {
  comment: Joi.string().min(1).max(commentMaxLength).required(),
};

const ReplyForm = (props: Props) => {
  const { submitNewSubcomment, commentID, setShowSubcomments } = props;
  const [fields, setFields] = useState({
    comment: "",
  });
  const [errors, setErrors] = useState({
    comment: "",
  });

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const { error } = validate(fields, Object.keys(fields), formSchema);
    if (!error) {
      submitNewSubcomment({
        content: fields.comment,
        commentID: commentID,
      });
      setFields({
        comment: "",
      });
      setShowSubcomments(true);
    } else {
      const label = error.details[0].context?.label;
      if (label) {
        setErrors({ ...errors, [label]: error.message });
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    const fieldName = e.currentTarget.name;
    const { error } = validate({ [fieldName]: value }, [fieldName], formSchema);
    if (error) {
      setErrors({ ...errors, [fieldName]: error.message });
    } else {
      setErrors({ ...errors, [fieldName]: "" });
    }
    setFields({ ...fields, [fieldName]: value });
  };

  return (
    <form className="reply-form">
      <TextArea
        textAreaClass="reply-form__comment-field"
        onChange={handleChange}
        name="comment"
        value={fields.comment}
        error={errors.comment}
        rows={2}
      >
        <button className="reply-form__submit-btn" onClick={handleSubmit}>
          Reply
        </button>
      </TextArea>
    </form>
  );
};

export default ReplyForm;
