import { Phrase, PhraseMatch } from "kb-lexicon/dist/src/types/Interfaces";

interface Props {
  setActivePhrase: (phraseMatch: PhraseMatch | null) => void;
  activeEditor: null | number;
  phrase: Phrase;
}

const PhraseHeader = ({ setActivePhrase, activeEditor, phrase }: Props) => {
  const phraseHeaderClasses = () => {
    let classes = ["phrase-header"];
    if (phrase.warningLevel === "Forbidden") classes.push("phrase-header--forbidden");
    if (phrase.warningLevel === "Warning") classes.push("phrase-header--warning");
    if (phrase.warningLevel === "Established") classes.push("phrase-header--established");
    return classes.join(" ");
  };

  return (
    <div className={phraseHeaderClasses()}>
      {activeEditor == null && (
        <button className="phrase-header__back-btn" onClick={() => setActivePhrase(null)}>
          Back
        </button>
      )}
      <h3 className="phrase-header__title">{phrase.title}</h3>
      <p className="phrase-header__warning-level">{phrase.warningLevel}</p>
      <p className="phrase-header__description">{phrase.description}</p>
      <p className="phrase-header__author">
        Edited by {phrase.lastEditedBy} on {phrase.lastEdited}
      </p>
    </div>
  );
};

export default PhraseHeader;
