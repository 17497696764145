import React, { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  variant?: "full" | "contained";
};

const Container: React.FC<Props> = ({ children, variant = "contained" }) => {
  const classes = () => {
    switch (variant) {
      case "full":
        return "container__container container__container--full";
      default:
        return "container__container container__container--contained";
    }
  };
  return <div className={classes()}>{children}</div>;
};

export default Container;
