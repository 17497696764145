import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import getCommentsQuery from "../../gql/queries/commentQuery";
import createCommentMutation from "../../gql/mutations/createCommentMutation";
import createSubcommentMutation from "../../gql/mutations/createSubcommentMutation";
import deleteCommentMutation from "../../gql/mutations/deleteCommentMutation";
import deleteSubcommentMutation from "../../gql/mutations/deleteSubcommentMutation";
import resolveCommentMutation from "../../gql/mutations/resolveCommentMutation";
import LexiconAPI from "../../helpers/LexcionGqlAPI";
import { SubcommentInput, NewCommentInput, LexiconComment, CommentsResponse } from "../../types";
import { useEntity } from "./EntityProvider";
import { useUser } from "./UserProvider";
import { AppStateAction, useAppState } from "./AppStateProvider";

interface CommentsContext {
  list: LexiconComment[];
  setComments: (list: LexiconComment[]) => void;
  delete: (id: number) => Promise<void>;
  deleteSub: (id: number) => Promise<void>;
  submit: (input: NewCommentInput) => Promise<void>;
  submitSub: (input: SubcommentInput) => Promise<void>;
  resolve: (id: number, status: boolean) => Promise<void>;
  isLoading: boolean;
}

interface Props {}

export const useComments = () => {
  return useContext(commentsContext);
};

const commentsContext = createContext<CommentsContext>({
  list: [],
  setComments: (list: LexiconComment[]) => {},
  delete: async (id: number) => {},
  deleteSub: async (id: number) => {},
  submit: async (input: NewCommentInput) => {},
  submitSub: async (input: SubcommentInput) => {},
  resolve: async (id: number, status: boolean) => {},
  isLoading: false,
});

const getWindowUrl = (id: string | number) => {
  return `https://partnerinriver2euw.productmarketingcloud.com/app/enrich#entity/${id}/custom-editor-7`;
};

const CommentsProvider: React.FC<Props> = ({ children }) => {
  const [comments, setComments] = useState<LexiconComment[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoaded, setHasloaded] = useState(false);
  const AppState = useAppState();
  const user = useUser();
  const { id: entityId, LMSId } = useEntity();

  useEffect(() => {
    if (comments && !comments.length && !hasLoaded && LMSId) {
      setIsLoading(true);
      const fetchComments = (entityId: number) => {
        LexiconAPI.post(getCommentsQuery(LMSId))
          .then(({ data }: CommentsResponse) => {
            if (!data.errors) {
              setComments(data.data.readComments);
            } else {
              toast.error("Error fetching comments");
              console.log("Error fetching comments: ", data.errors);
            }
            setIsLoading(false);
            setHasloaded(true);
          })
          .catch((err) => {
            toast.error("Failed to post comment");
            console.log("Error with comments API: ", err);
          });
      };
      if (entityId) {
        fetchComments(entityId);
      }
    }
  }, [comments, entityId, hasLoaded, LMSId]);

  const deleteComment = (id: number) => {
    setComments(comments.filter((comment) => comment.id !== id));
    return LexiconAPI.post(deleteCommentMutation(id))
      .then(({ data }) => {
        if (data.errors) {
          toast.error("Error deleting comment");
          console.log("Error deleting comment: ", data.errors);
        }
      })
      .catch((err) => {
        toast.error("Error deleting comment");
        console.log("API Issue deleting comment: ", err.errors);
      });
  };

  const deleteSubcomment = (id: number) => {
    setComments(
      comments.map((comment) =>
        comment.subcomments.nodes.findIndex((subcomment) => subcomment.id === id) < 0
          ? comment
          : {
              ...comment,
              subcomments: {
                nodes: comment.subcomments.nodes.filter((subcomment) => subcomment.id !== id),
              },
            }
      )
    );
    return LexiconAPI.post(deleteSubcommentMutation(id))
      .then(({ data }) => {
        if (data.errors) {
          toast.error("Error deleting subcomment");
          console.log("Error deleting subcomment: ", data.errors);
        }
      })
      .catch((err) => {
        toast.error("Error deleting subcomment");
        console.log("API issue deleting subcomment: ", err);
      });
  };

  const submitNewSubcomment = (input: SubcommentInput) => {
    const mutationData = {
      authorEmail: user.email ? user.email : "Anonymous",
      authorName: user.name ? user.name : "Anonymous",
      content: input.content,
      commentID: input.commentID,
    };

    const query = createSubcommentMutation(mutationData);
    return LexiconAPI.post(query)
      .then(({ data }: any) => {
        if (!data.errors) {
          setComments(
            comments.map((comment) =>
              comment.id !== data.data.createSubcomment.commentID
                ? comment
                : {
                    ...comment,
                    subcomments: {
                      nodes: [...comment.subcomments.nodes, data.data.createSubcomment],
                    },
                  }
            )
          );
        } else {
          toast.error("Error creating comment");
          console.log("Error creating subcomment: ", data.errors);
        }
      })
      .catch((err) => {
        toast.error("Error creating comment");
        console.log("API issue submitting subcomment: ", err);
      });
  };

  const submitNewComment = (input: NewCommentInput) => {
    if (!LMSId) {
      return new Promise<void>(() => {});
    }
    const parentURL = entityId ? getWindowUrl(entityId) : "";
    const mutationData = {
      entityId: LMSId,
      authorEmail: user.email ? user.email : "Anonymous",
      authorName: user.name ? user.name : "Anonymous",
      fieldId: input.fieldId,
      content: input.content,
      resolved: false,
      subject: input.subject,
      quote: input.quote,
      link: parentURL ? parentURL : window.location.href,
    };

    const comment = {
      id: 0,
      lastEdited: Date.now().toString(),
      created: Date.now().toString(),
      authorEmail: user.email ? user.email : "Anonymous",
      authorName: user.name ? user.name : "Anonymous",
      fieldId: input.fieldId,
      content: input.content,
      resolved: false,
      subject: input.subject,
      quote: input.quote,
      subcomments: {
        nodes: [],
      },
    };
    setComments((prev) => {
      return [comment, ...prev];
    });

    const query = createCommentMutation(mutationData);
    AppState.dispatch({ type: AppStateAction.setShowNewComment, payload: false });
    AppState.dispatch({ type: AppStateAction.setSidebarView, payload: "comments" });
    return LexiconAPI.post(query)
      .then(({ data }: any) => {
        if (!data.errors) {
          const tmp = [data.data.createComment, ...comments];
          setComments(tmp);
        } else {
          setComments(comments);
          toast.error("Error creating comment");
          console.log("Error creating comment: ", data.errors);
        }
      })
      .catch((err) => {
        setComments(comments);
        toast.error("Error creating comment");
        console.log("Unable to submit comment: ", err);
      });
  };

  const resolveComment = (id: number, status: boolean) => {
    setComments(
      comments.map((comment) => (comment.id === id ? { ...comment, resolved: status } : comment))
    );
    return LexiconAPI.post(resolveCommentMutation(id, status))
      .then(({ data }) => {
        if (data.errors) {
          toast.error("Couldn't resolve comment");
          console.log("Error resolving comment: ", data.errors);
        }
      })
      .catch((err) => {
        toast.error("Couldn't resolve comment");
        console.log("API Issue resolving comment: ", err);
      });
  };

  const contextValue = {
    list: comments,
    setComments,
    delete: deleteComment,
    deleteSub: deleteSubcomment,
    submit: submitNewComment,
    submitSub: submitNewSubcomment,
    resolve: resolveComment,
    isLoading,
  };
  return <commentsContext.Provider value={contextValue}>{children}</commentsContext.Provider>;
};

export default CommentsProvider;
