import { EditorState, Entity, SelectionState } from "draft-js";
import "draft-js/dist/Draft.css";
import { PhraseMatch } from "kb-lexicon/dist/src/types/Interfaces";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { AppStateAction, useAppState } from "../components/contextProviders/AppStateProvider";
import { useComments } from "../components/contextProviders/CommentsProvider";
import { useEditors } from "../components/contextProviders/EditorProvider";
import { useWorkflow } from "../components/contextProviders/EntityProvider";
import {
  GlobalAppStateAction,
  useGlobalState
} from "../components/contextProviders/GlobalAppStateProvider";
import { useID } from "../components/contextProviders/IDProvider";
import { LexiconContext } from "../components/contextProviders/LexiconProvider";
import { localeContext } from "../components/contextProviders/LocaleProvider";
import { useUser } from "../components/contextProviders/UserProvider";
import Loading from "../components/Loading";
import MainContainer from "../components/MainContainer";
import AppNavbar from "../components/organisms/AppNavbar";
import Sidebar from "../components/Sidebar";
import addDecorators from "../helpers/addDecorators";
import findLexiconMatches from "../helpers/findLexiconMatches";
import LMSLocaleAccessCode from "../helpers/LMSAccessLocaleCode";
import useAppEffects from "../hooks/useAppEffects";
import useCurrentSelection from "../hooks/useCurrentSelection";
import { Editor, EditorHits, Panel } from "../types";

const Home = () => {
  // Effect handler that reacts to the global and app state to perform actions
  useAppEffects();

  const { currentLocale, comparisonLocale } = useContext(localeContext);
  const { state: appState, dispatch: appStateDispatch } = useAppState();
  const { state: globalState, dispatch: globalDispatch } = useGlobalState();
  const [activePhrase, setActivePhrase] = useState<PhraseMatch | null>(null);
  const [lexiconHits, setLexiconHits] = useState<EditorHits[]>([]);
  const [comparisonHits, setComparisonHits] = useState<EditorHits[]>([]);
  const [quote, setQuote] = useState("");
  const { id: entityId } = useID();
  const [updatingHits, setUpdatingHits] = useState(false);
  const {
    lexicon,
    isLoading: lexiconLoading,
    hasUpdated,
    setHasUpdated: setLexiconUpdated
  } = useContext(LexiconContext);
  const Comments = useComments();
  const {
    editors,
    setEditors,
    filter: editorFilter,
    activePanel,
    setActivePanel,
    activeEditor,
    activeEditorId,
    setActiveEditorId,
    hasLoaded: editorsLoaded
  } = useEditors();

  const { currentSelection, setCurrentSelection } = useCurrentSelection({
    activeEditor: activeEditorId,
    setActivePhrase,
    lexiconHits,
    comparisonHits
  });

  const user = useUser();
  const workflow = useWorkflow();

  // Updates decorators and find inital lexicon matches when new lexicon is loaded
  useEffect(() => {
    if (hasUpdated && editorsLoaded) {
      let updatedEditors = addDecorators(editors, lexicon, currentLocale);
      updatedEditors = addDecorators(updatedEditors, lexicon, comparisonLocale);
      const hits = findLexiconMatches(editors, lexicon, currentLocale);
      const comparisonHits = findLexiconMatches(editors, lexicon, comparisonLocale);

      setLexiconHits(hits);
      setComparisonHits(comparisonHits);
      setEditors(updatedEditors);
      setLexiconUpdated(false);
      appStateDispatch({ type: AppStateAction.setIsLoading, payload: false });
    }
  }, [
    globalState.showAllFields,
    currentLocale,
    editors,
    editorsLoaded,
    lexicon,
    hasUpdated,
    setEditors,
    editorFilter,
    setLexiconUpdated,
    comparisonLocale,
    appStateDispatch
  ]);

  useEffect(() => {
    const resetAppState = () => {
      setActiveEditorId(null);
      setActivePhrase(null);
      setCurrentSelection(null);
    };

    if (globalState.activeEntityId !== entityId) {
      resetAppState();
    }
  }, [globalState.activeEntityId, entityId, setActiveEditorId, setCurrentSelection]);

  useEffect(() => {
    if (appState.isEditable) {
      if (
        (!user.canEditSteps.includes(workflow.currentStep) ||
          !user.permissions.find(
            (permission) => permission.code === LMSLocaleAccessCode(currentLocale)
          )) &&
        !user.permissions.find((permission) => permission.code === "ADMIN")
      ) {
        appStateDispatch({
          type: AppStateAction.setIsEditable,
          payload: false
        });
      }
    } else {
      if (
        (user.canEditSteps.includes(workflow.currentStep) &&
          user.permissions.find(
            (permission) => permission.code === LMSLocaleAccessCode(currentLocale)
          )) ||
        user.permissions.find((permission) => permission.code === "ADMIN")
      ) {
        appStateDispatch({ type: AppStateAction.setIsEditable, payload: true });
      }
    }
  }, [
    appState.isEditable,
    user.canEditSteps,
    workflow.currentStep,
    currentLocale,
    user.permissions,
    appStateDispatch
  ]);

  //Updates decorators for the comparison view
  const filterComments = () => {
    const comments = Comments.list;
    if (!activeEditor) return comments;
    else {
      return comments.length > 0
        ? comments.filter((comment) => comment.fieldId === activeEditor.fieldTypeId)
        : comments;
    }
  };

  // Filters lexicon-hits for use in the sidebar list-view
  const filterLexiconHits = (hits: EditorHits[]): PhraseMatch[] => {
    const filteredEditorIndexes =
      globalState.showAllFields || activeEditorId
        ? editors.map((editor) => editors.findIndex((tmp) => tmp.id === editor.id))
        : editors
            .filter((editor) => !editorFilter.includes(editor.fieldTypeId))
            .map((editor) => editors.findIndex((tmp) => tmp.id === editor.id));

    const filteredHits = hits.filter((_, index) => {
      return filteredEditorIndexes.includes(index);
    }); // Filters based on fieldSet filters etc

    if (filteredHits.length < 1 || lexiconLoading) return [];
    if (!activeEditor) return sortLexiconHits(mergeHitsArrays(filteredHits));
    return sortLexiconHits(mergeLexiconHitsBlocks(hits[activeEditor.id]));
  };

  // Merges hit arrays for all editors for use in the listview in the sidebar.
  const mergeHitsArrays = (hits: EditorHits[]): PhraseMatch[] => {
    const combinedArray: PhraseMatch[] = [];
    let lexiconHitsCopy = _.cloneDeep(hits);
    lexiconHitsCopy.forEach((editorBlocks) => {
      for (const key in editorBlocks) {
        if (Object.prototype.hasOwnProperty.call(editorBlocks, key)) {
          const block = editorBlocks[key];
          block.forEach((hit) => {
            const indexInCombined = combinedArray.findIndex(
              (match) => match.phrase.title === hit.phrase.title
            );
            if (indexInCombined >= 0) {
              combinedArray[indexInCombined].matches = [
                ...combinedArray[indexInCombined].matches,
                ...hit.matches
              ];
            } else {
              combinedArray.push(hit);
            }
          });
        }
      }
    });

    return combinedArray;
  };

  const mergeLexiconHitsBlocks = (blocks: EditorHits) => {
    const mergedHits: PhraseMatch[] = [];
    if (activeEditorId !== null) {
      for (const key in blocks) {
        if (Object.prototype.hasOwnProperty.call(blocks, key)) {
          const block = blocks[key];
          block.forEach((hit) => {
            let saved = false;
            mergedHits.forEach((savedHit) => {
              if (savedHit.phrase.title === hit.phrase.title) {
                savedHit.matches = [...savedHit.matches, ...hit.matches];
                saved = true;
              }
            });
            if (!saved) {
              mergedHits.push(_.cloneDeep(hit));
            }
          });
        }
      }
    }

    return mergedHits;
  };

  // Handles updates of Editorstates, selections and lexiconhits on user input
  const handleChange = (
    newState: EditorState,
    editorId: number,
    panel: Panel,
    locale: string
  ): void => {
    // Selection is update both if the panel is main or comparison
    handleSelectionUpdate(newState, editorId, panel);

    // All the following updates are only done if the change happened in the main panel
    if (panel !== "main") return;

    updateEditor(newState, editorId);
    if (editorsLoaded && panel === "main" && appState.isEditable && activeEditor?.id === editorId) {
      const oldState = activeEditor?.editorStates[locale];
      if (!updatingHits) {
        setUpdatingHits(true);
        setTimeout(() => updateLexiconHits(newState, oldState, editorId), 0);
        setUpdatingHits(false);
      }
    }
  };

  //handles updates of activeEditor
  const handleEditorFocus = (id: number | null, panel: Panel) => {
    setActiveEditorId(id);
    setActivePanel(panel);

    // Sets the selection of the clicked editor if you click the padding.
    if (id !== null && activeEditorId !== id && panel === "main") {
      const oldState = editors.find((editor) => editor.id === id)?.editorStates[currentLocale];
      if (!oldState) return;
      const newState = EditorState.moveFocusToEnd(oldState);
      updateEditor(newState, id);
    }

    if (globalState.activeEntityId !== entityId) {
      globalDispatch({ type: GlobalAppStateAction.setActiveEntityId, payload: entityId });
    }
  };

  // handles updates of currentSelection
  const handleSelectionUpdate = (newState: EditorState, editorId: number, panel: Panel) => {
    const selection = newState.getSelection();
    const focusOffset = selection.getFocusOffset();
    const focusKey = selection.getFocusKey();
    if (
      currentSelection?.editorId !== editorId ||
      currentSelection.position !== focusOffset ||
      currentSelection.key !== focusKey
    ) {
      setCurrentSelection({
        editorId,
        panel,
        position: focusOffset,
        key: focusKey
      });
    }
  };

  // Sorts lexicon hits for the hitsList
  const sortLexiconHits = (hitsArray: PhraseMatch[]) => {
    hitsArray.sort((a: PhraseMatch, b: PhraseMatch) => {
      if (a.phrase.title > b.phrase.title) return 1;
      if (a.phrase.title < b.phrase.title) return -1;
      return 0;
    });
    hitsArray.sort((a: PhraseMatch, b: PhraseMatch) => {
      if (a.matches.length < b.matches.length) return 1;
      if (a.matches.length > b.matches.length) return -1;
      return 0;
    });

    return hitsArray;
  };

  const resetSelectionState = (editor: Editor) => {
    const newState = EditorState.acceptSelection(
      editor.editorStates[currentLocale],
      SelectionState.createEmpty(
        editor.editorStates[currentLocale].getCurrentContent().getFirstBlock().getKey()
      )
    );
    updateEditor(newState, editor.id);
  };

  // Used to clear all "actives", activeEditor, on clicking outside an editor
  const unfocusAll = () => {
    if (!activeEditor) return;

    if (!appState.showNewComment) {
      setActiveEditorId(null);
      setActivePhrase(null);
      setActivePanel("main");
      setCurrentSelection(null);
    }
  };

  //Updates an editor with a new state
  const updateEditor = (newState: EditorState, editorId: number) => {
    setEditors(
      editors.map((editor) =>
        editor.id === editorId
          ? {
              ...editor,
              editorStates: {
                ...editor.editorStates,
                [currentLocale]: newState
              }
            }
          : editor
      )
    );
  };

  //updates lexiconhits based on a new editorState
  const updateLexiconHits = (newState: EditorState, oldState: EditorState, editorId: number) => {
    const currentBlocksMap = oldState.getCurrentContent().getBlockMap();
    const newBlocks = newState.getCurrentContent().getBlocksAsArray();
    const oldBlocks = oldState.getCurrentContent().getBlocksAsArray();

    let hits: { [key: string]: PhraseMatch[] } = {};
    let deleted: string[] = [];

    newBlocks.forEach((newBlock) => {
      const key = newBlock.getKey();
      const currentBlock = currentBlocksMap.get(key);
      if (currentBlock) {
        if (currentBlock.getText() !== newBlock.getText()) {
          hits[key] = lexicon.checkText(newBlock.getText());
        }
      } else {
        deleted.push(key);
      }
    });

    oldBlocks.forEach((oldBlock) => {
      if (!newState.getCurrentContent().getBlockForKey(oldBlock.getKey()))
        deleted.push(oldBlock.getKey());
    });

    setLexiconHits((lexiconHits) => {
      for (const key in hits) {
        if (Object.prototype.hasOwnProperty.call(hits, key)) {
          lexiconHits[editorId][key] = hits[key];
        }
      }
      deleted.forEach((key) => {
        delete lexiconHits[editorId][key];
      });
      return lexiconHits;
    });
  };

  const handleTranslation = (editors: Editor[]) => {
    let updatedEditors = addDecorators(editors, lexicon, currentLocale);
    updatedEditors = addDecorators(updatedEditors, lexicon, comparisonLocale);
    const hits = findLexiconMatches(editors, lexicon, currentLocale);
    const comparisonHits = findLexiconMatches(editors, lexicon, comparisonLocale);

    setLexiconHits(hits);
    setComparisonHits(comparisonHits);
    setEditors(updatedEditors);
    setLexiconUpdated(false);
    appStateDispatch({ type: AppStateAction.setIsLoading, payload: false });
  };

  const handleActiveApp = () => {
    if (globalState.activeEntityId !== entityId) {
      globalDispatch({ type: GlobalAppStateAction.setActiveEntityId, payload: entityId });
    }
  };

  if (appState.isLoading) return <Loading />;

  const filteredHits =
    activePanel === "main" ? filterLexiconHits(lexiconHits) : filterLexiconHits(comparisonHits);

  return (
    <div onClick={handleActiveApp}>
      <AppNavbar />
      <div className="home__inner-container">
        <div className="home__main" onClick={unfocusAll}>
          <MainContainer
            setQuote={setQuote}
            handleEditorFocus={handleEditorFocus}
            handleChange={handleChange}
            onTranslation={handleTranslation}
          />
        </div>
        <Sidebar
          activePhrase={activePhrase}
          activePanel={activePanel}
          comments={filterComments()}
          hitsList={filteredHits}
          resetSelectionState={resetSelectionState}
          setActiveEditor={setActiveEditorId}
          setActivePhrase={setActivePhrase}
          quote={quote}
        />
      </div>
    </div>
  );
};

export default Home;
