import React, { createContext, ReactNode, useContext, useState } from "react";

type Props = {
  children: ReactNode;
};

export type PreviewContext = {
  content: EntityContent[];
  addNode: (node: EntityContent) => void;
  updateNode: (entityId: number, fields: Field[]) => void;
  removeNode: (entityId: number) => void;
};

export type EntityContent = {
  entityId: number;
  parentId: number;
  sortOrder: number;
  fields: Field[];
};

export type Field = {
  fieldTypeId: string;
  element: HeadingLevel | "p";
  content: string;
};

export type HeadingLevel = "h1" | "h2" | "h3";
export type TagType = HeadingLevel | "p";

const previewContext = createContext<PreviewContext>({
  content: [],
  addNode: (node: Omit<EntityContent, "childrenIds">) => {},
  updateNode: (entityId: number, fields: Field[]) => {},
  removeNode: (entityId: number) => {}
});

export const usePreview = () => useContext(previewContext);

const PreviewProvider: React.FC<Props> = ({ children }) => {
  const [content, setContent] = useState<EntityContent[]>([]);

  const addNode = (node: EntityContent) => {
    const existing = content.find((item) => item.entityId === node.entityId);

    if (existing) return;

    setContent((prev) => [...prev, node]);
  };

  const updateNode = (entityId: number, fields: Field[]) => {
    const existingIndex = content.findIndex((item) => item.entityId === entityId);
    if (existingIndex < 0) return;

    const existing = content[existingIndex];
    const newNode = {
      ...existing,
      fields: fields
    };

    setContent((prev) => [...prev.splice(existingIndex, 1), newNode]);
  };

  const removeNode = (entityId: number) => {
    setContent((prev) => prev.filter((node) => node.entityId !== entityId));
  };

  const context: PreviewContext = {
    content,
    addNode,
    updateNode,
    removeNode
  };

  return <previewContext.Provider value={context}>{children}</previewContext.Provider>;
};

export default PreviewProvider;
