import React, { ReactNode } from "react";
import { GlobalAppStateAction, useGlobalState } from "./contextProviders/GlobalAppStateProvider";
import OptionsItem from "./OptionsItem";
import { ReactComponent as SaveIcon } from "../img/icon-floppy.svg";

type Props = {
  children?: ReactNode;
};

const SaveButton: React.FC<Props> = ({ children }) => {
  const { state, dispatch } = useGlobalState();

  // console.log(!Object.values(state.saveStates).reduce((acc, cur) => !(!acc && !cur), false));
  const isSaved = () => {
    return !Object.values(state.saveStates).reduce((acc, cur) => !(!acc && !cur), false);
  };

  const disabled = !isSaved();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;
    dispatch({ type: GlobalAppStateAction.resetSaveStates });
  };

  return (
    <button className="save-button__button" onClick={handleClick} disabled={disabled}>
      <OptionsItem
        padding="2 0 0 0"
        icon={SaveIcon}
        iconActive={SaveIcon}
        name="save"
        tooltip=""
        isActive={false}
        element="div"
      />
      <span>{disabled ? "..." : "Save"}</span>
    </button>
  );
};

export default SaveButton;
