import React, { ReactNode } from "react";
import Container from "./atoms/Container";
import { useGlobalState } from "./contextProviders/GlobalAppStateProvider";
import { Field, usePreviewStore } from "./contextProviders/PreviewStore";

type Props = {};

const PreviewModal: React.FC<Props> = () => {
  const { state } = useGlobalState();
  const nodes = usePreviewStore((store) => store.nodes);

  if (!state.showPreview) return <></>;

  const getFieldElement = (
    field: Field,
    index: number,
    comparisonField: Field
  ): ReactNode | null => {
    if (!state.showAllFields && field.isFiltered) return null;

    const elements = [];

    if (state.showComparison && comparisonField) {
      switch (comparisonField.element) {
        case "h1":
          elements.push(<h1 key={`comparison-${index}`}>{comparisonField.content}</h1>);
          break;
        case "h2":
          elements.push(<h2 key={`comparison-${index}`}>{comparisonField.content}</h2>);
          break;
        case "h3":
          elements.push(<h3 key={`comparison-${index}`}>{comparisonField.content}</h3>);
          break;
        case "p":
          elements.push(<p key={`comparison-${index}`}>{comparisonField.content}</p>);
          break;
        default:
          break;
      }
    }

    switch (field.element) {
      case "h1":
        elements.push(<h1 key={index}>{field.content}</h1>);
        break;
      case "h2":
        elements.push(<h2 key={index}>{field.content}</h2>);
        break;
      case "h3":
        elements.push(<h3 key={index}>{field.content}</h3>);
        break;
      case "p":
        elements.push(<p key={index}>{field.content}</p>);
        break;
      default:
        break;
    }

    const className = () => {
      if (!state.showComparison) return "preview-modal__fields-container";
      return "preview-modal__fields-container--comparison";
    };

    if (!field.content && !comparisonField.content) {
      return null;
    } else {
      console.log(field.fieldTypeId);
    }

    return <div className={className()}>{elements.map((element) => element)}</div>;
  };

  const getNodeElements = (key: string) => {
    const node = nodes[parseInt(key)];
    if (!node) return;

    const getElements = () => {
      return node.fields
        .map((field, index) => {
          const comparisonField = node.comparisonFields[index];
          return getFieldElement(field, index, comparisonField);
        })
        .filter((element) => !!element);
    };

    const elements = getElements();

    if (!elements.length) return;

    return <div className={"preview-modal__entity-wrapper"}>{elements}</div>;
  };

  // const nodeArr = Object.keys(nodes).map((key) => nodes[parseInt(key)]);

  return (
    <div className="preview-modal__container">
      <Container variant={state.showComparison ? "full" : "contained"}>
        {Object.keys(nodes).map(getNodeElements)}
      </Container>
    </div>
  );
};

export default PreviewModal;
