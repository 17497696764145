import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import EntityApp from "../EntityApp";
import { GlobalAppStateAction, useGlobalState } from "./GlobalAppStateProvider";
import { useTemplateData } from "./InRiverTemplateDataProvider";
import { usePreviewStore } from "./PreviewStore";

const OuterApp: React.FC = ({ children }) => {
  const templateData = useTemplateData();

  const [skippedFirst, setSkippedFirst] = useState(false);

  const { addNode } = usePreviewStore();
  const { state, dispatch: globalDispatch } = useGlobalState();

  useEffect(() => {
    if (state.showPreview) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [state.showPreview]);

  useEffect(() => {
    const savedAll = Object.keys(state.saveStates).reduce((acc: boolean, cur: string): boolean => {
      return acc && !!state.saveStates[parseInt(cur)];
    }, true);

    if (savedAll) {
      if (skippedFirst) {
        toast.success("Saved all entities");
      } else {
        setSkippedFirst(true);
      }
    }
  }, [state.saveStates]);

  useEffect(() => {
    globalDispatch({
      type: GlobalAppStateAction.setActiveEntityId,
      payload: templateData.id
    });
  }, [globalDispatch, templateData.id]);

  useEffect(() => {
    addNode({
      entityId: templateData.id,
      parentId: 0,
      sortOrder: 0,
      fields: [],
      comparisonFields: []
    });
    globalDispatch({
      type: GlobalAppStateAction.addSaveState,
      payload: {
        id: templateData.id,
        shouldSave: false
      }
    });
  }, [templateData.id, addNode, globalDispatch]);

  return <EntityApp {...templateData} currentDepth={0} />;
};

export default OuterApp;
