import Home from "../layouts/Home";
import AppMapper from "./AppMapper";
import ContextsProvider from "./contextProviders/ContextsProvider";

interface Props {
  id: number;
  entityTypeId: string;
  sourceName: string;
  entityDisplayName: string;
  currentDepth: number;
}

const EntityApp: React.FC<Props> = (props) => {
  return (
    <ContextsProvider {...props}>
      <Home />
      <AppMapper id={props.id} />
    </ContextsProvider>
  );
};
export default EntityApp;
