import { Editor } from "../types";

const getEditorText = (locale: string, editor: Editor) => {
  const content = editor.editorStates[locale].getCurrentContent();
  const text = content.getPlainText();
  return {
    id: editor.id,
    text
  };
};

export default getEditorText;
