import { Editor, EditorHits } from "../types";

const findLexiconMatches = (editors: Editor[], lexicon: any, locale: string) => {
  let hits: EditorHits[] = [];

  editors.forEach((editor, index) => {
    const blocks = editor.editorStates[locale].getCurrentContent().getBlocksAsArray();
    hits[editor.id] = {};

    for (let blockId = 0; blockId < blocks.length; blockId++) {
      hits[editor.id][blocks[blockId].getKey()] = lexicon.checkText(
        blocks[blockId].getText(),
        locale
      );
    }
  });

  return hits;
};

export default findLexiconMatches;
