import React from "react";
import { useWorkflow } from "./contextProviders/EntityProvider";
interface Props {}

const colors = [
  "ffadad",
  "ffd6a5",
  "fdffb6",
  "caffbf",
  "9bf6ff",
  "a0c4ff",
  "bdb2ff",
  "ffc6ff",
  "fffffc"
];

const WorkflowDisplay: React.FC<Props> = ({ children }) => {
  const workflow = useWorkflow();
  const getClass = (id: number) => {
    const curIndex = workflow.steps.findIndex((step) => step.id === workflow.currentStep);
    const stepIndex = workflow.steps.findIndex((step) => step.id === id);
    const classes =
      curIndex > stepIndex
        ? "workflow-display__workflow-step workflow-display__workflow-step--completed"
        : curIndex < stepIndex
        ? "workflow-display__workflow-step workflow-display__workflow-step--inactive"
        : "workflow-display__workflow-step workflow-display__workflow-step--active";
    return classes;
  };

  const getSteps = () => {
    return workflow.steps.map((workflowStep, index) => {
      return (
        <div
          key={`step_${workflowStep.id}_${index}`}
          className="workflow-display__workflow-step-container"
        >
          <span
            className={`workflow-display__workflow-step-text-container${
              workflowStep.id === workflow.currentStep
                ? " workflow-display__workflow-step-text-container--active"
                : ""
            }`}
          >
            <span className={getClass(workflowStep.id)}>
              <span style={{ color: `#${colors[index % (colors.length - 1)]}` }}>{index + 1}</span>.{" "}
              {workflowStep.title}
            </span>
          </span>
        </div>
      );
    });
  };

  return <>{workflow.steps.length > 0 && <div className="workflow-display">{getSteps()}</div>}</>;
};

export default WorkflowDisplay;
