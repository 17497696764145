import React from "react";
import CommentsProvider from "../../components/contextProviders/CommentsProvider";
import EditorProvider from "../../components/contextProviders/EditorProvider";
import EntityProvider from "../../components/contextProviders/EntityProvider";
import LexiconProvider from "../../components/contextProviders/LexiconProvider";
import AppStateProvider from "./AppStateProvider";
import FieldsProvider from "./FieldsProvider";
import IDProvider from "./IDProvider";

interface Props {
  id: number;
  entityTypeId: string;
  entityDisplayName: string;
  sourceName: string;
  currentDepth: number;
}

const ContextsProvider: React.FC<Props> = ({
  children,
  id,
  entityTypeId,
  entityDisplayName,
  sourceName,
  currentDepth
}) => {
  return (
    <IDProvider
      id={id}
      entityTypeId={entityTypeId}
      sourceName={sourceName}
      entityDisplayName={entityDisplayName}
    >
      <AppStateProvider currentDepth={currentDepth}>
        <EntityProvider>
          <FieldsProvider>
            <CommentsProvider>
              <LexiconProvider>
                <EditorProvider>{children}</EditorProvider>
              </LexiconProvider>
            </CommentsProvider>
          </FieldsProvider>
        </EntityProvider>
      </AppStateProvider>
    </IDProvider>
  );
};

export default ContextsProvider;
