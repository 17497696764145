import React, { SyntheticEvent, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";
import { useEditors } from "../contextProviders/EditorProvider";
import { useEntity, useWorkflow } from "../contextProviders/EntityProvider";
import { useLocales } from "../contextProviders/LocaleProvider";
import WorkflowDisplay from "../WorkflowDisplay";

const AppNavbar: React.FC = () => {
  return (
    <div className="app-navbar">
      <AppNavbarHeading />
      <div className="app-navbar__workflow-container">
        <WorkflowDisplay />
        <div className="app-navbar__workflow-spacing" />
      </div>
      <div className="app-navbar__button-container">
        <BackButton />
        <NextButton />
        <SaveButton />
      </div>
    </div>
  );
};

export default AppNavbar;

const AppNavbarHeading = () => {
  const { entityTypeDisplayName } = useEntity();
  return (
    <div className="app-navbar__heading">
      {/* <OptionsButton /> */}
      {entityTypeDisplayName}
    </div>
  );
};

const SaveButton = () => {
  const { save } = useEditors();

  const handleSave = () => {
    save({
      onSuccess: () => {
        toast.success("Saved");
      }
    });
  };
  return (
    <Button color="green" onClick={handleSave}>
      Save
    </Button>
  );
};

const BackButton = () => {
  const workflow = useWorkflow();
  const Entity = useEntity();
  const { editors, save } = useEditors();
  const { currentLocale } = useLocales();

  const handlePrev = () => {
    if (workflow.nextStepId === 0) {
      const res = window.confirm(`Save and send to draft?`);
      if (res) {
        save();
        Entity.createVersion(editors, currentLocale, workflow.currentStep);
        workflow.reset();
      }
    } else {
      const res = window.confirm(`Save and send to previous step?`);
      if (res) {
        save();
        workflow.undoStep();
      }
    }
  };

  if (workflow.prevStepId === 0) return <></>;

  return (
    <Button onClick={handlePrev} className="app-navbar__button__arrow-left">
      {workflow.prevStepOrder}.{workflow.prevStepTitle}
    </Button>
  );
};

const NextButton = () => {
  const workflow = useWorkflow();
  const Entity = useEntity();
  const { editors, save } = useEditors();
  const { currentLocale } = useLocales();
  const handleNext = () => {
    const res = window.confirm(`Save and submit to next step?`);
    if (res) {
      save();
      Entity.createVersion(editors, currentLocale, workflow.currentStep);
      workflow.completeStep();
    }
  };

  if (workflow.nextStepId === 0) return <></>;

  return (
    <Button onClick={handleNext} className="app-navbar__button__arrow-right">
      {workflow.nextStepOrder}.{workflow.nextStepTitle}
    </Button>
  );
};

const OptionsButton = () => {
  const [active, setActive] = useState(false);

  const handleClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    setActive((prev) => !prev);
  };

  const handleOutsideClick = (e: SyntheticEvent) => {
    setActive(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick} display="contents">
      <div className="app-navbar__options__container">
        <button className="app-navbar__options__button" onClick={handleClick} />
        {active && (
          <div className="app-navbar__options__holder">
            <div className="app-navbar__options__holder-item">Option 1</div>
            <div className="app-navbar__options__holder-item">Option 2</div>
            <div className="app-navbar__options__holder-item">Option 3</div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

type ButtonProps = {
  color?: "blue" | "green";
  onClick?: (args?: any) => void;
  className?: string;
};
const Button: React.FC<ButtonProps> = ({ children, color = "blue", onClick, className }) => {
  const getButtonClasses = () => {
    const classes = ["app-navbar__button"];
    if (className) classes.push(className);
    if (color === "blue") classes.push("app-navbar__button__blue");
    if (color === "green") classes.push("app-navbar__button__green");
    return classes.join(" ");
  };

  return (
    <button className={getButtonClasses()} onClick={onClick}>
      {children}
    </button>
  );
};
