import { CSSProperties } from "react";
import { LocaleObject } from "../../types";
import { useGlobalState } from "../contextProviders/GlobalAppStateProvider";
import LocaleSelector from "../LocaleSelector";

type Props = {
  locales: LocaleObject[];
  label?: string;
  currentLocale: string;
  excludeLocale?: string;
  closeBtnAction?: (arg: boolean) => void;
  setLocale: (locale: string) => void;
  selectName?: string;
  stripped?: boolean;
  style?: CSSProperties;
};

const OptionsBarLocaleSelector = (props: Props) => {
  const GlobalState = useGlobalState();

  const getContainerClasses = () => {
    const classes = ["options-bar-locale-selector"];
    if (GlobalState.state.showComparison) classes.push("options-bar-locale-selector--comparison");
    return classes.join(" ");
  };

  return (
    <div className={getContainerClasses()} style={props.style}>
      <LocaleSelector
        locales={props.locales}
        label={props.label}
        currentLocale={props.currentLocale}
        excludeLocale={props.excludeLocale}
        closeBtnAction={props.closeBtnAction}
        setLocale={props.setLocale}
        selectName={props.selectName}
        stripped={props.stripped !== undefined ? props.stripped : true}
      />
    </div>
  );
};

export default OptionsBarLocaleSelector;
