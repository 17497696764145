import React from "react";
import { EntityVersion } from "../types";
import formatDate from "../helpers/formatDate";
import { useWorkflow } from "./contextProviders/EntityProvider";
interface Props {
  version: EntityVersion;
  active: boolean;
  [key: string]: any;
}

const VersionItem: React.FC<Props> = ({ children, version, active, ...rest }) => {
  const workflow = useWorkflow();
  return (
    <li
      {...rest}
      style={{ textDecoration: active ? "underline" : "", color: active ? "#007af0" : "black" }}
    >
      <span style={{ fontWeight: "bold" }}>
        {workflow.steps.findIndex((step) => step.id === version.step.id) + 1}. {version.step.title}
      </span>{" "}
      <span>{formatDate(version.created).join(" ")}</span>
    </li>
  );
};

export default VersionItem;
