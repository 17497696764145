import { Editor } from "../types";
import GPTAPI, { EditorText } from "./GPTAPI";
import editorTextNotEmpty from "./editorTextNotEmpty";
import getEditorText from "./getEditorText";
import timeoutPromise from "./timeoutPromise";

const getFulfilled = (results: PromiseSettledResult<EditorText>[]) =>
  results.filter((res) => res.status === "fulfilled") as PromiseFulfilledResult<{
    id: number;
    text: string;
  }>[];

const translateEditors = async (editors: Editor[], from: string, to: string) => {
  const translate = GPTAPI.translateEditorText.bind(null, from, to);
  const retranslate = GPTAPI.translateEditorText.bind(null, to, from);
  const getEditorTextFrom = getEditorText.bind(null, from);
  const timeoutPromise10Seconds = <T>(promise: Promise<T>) => timeoutPromise(promise, 10000);

  const translations = await Promise.allSettled(
    editors
      .map(getEditorTextFrom)
      .filter(editorTextNotEmpty)
      .map(translate)
      .map(timeoutPromise10Seconds)
  ).then(getFulfilled);

  const retranslations = await Promise.allSettled(
    translations
      .map((res) => res.value)
      .map(retranslate)
      .map(timeoutPromise10Seconds)
  ).then(getFulfilled);

  return [translations, retranslations];
};

export default translateEditors;
