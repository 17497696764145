import { PhraseMatch } from "kb-lexicon/dist/src/types/Interfaces";
import HitItem from "./HitItem";

interface Props {
  hitsArray: PhraseMatch[];
  setActivePhrase: (phraseMatch: PhraseMatch | null) => void;
}
const HitsList = ({ hitsArray, setActivePhrase }: Props) => {
  return (
    <div className="hits-list__container">
      <ul className="hits-list__list">
        {hitsArray &&
          hitsArray.map((hit) => {
            return <HitItem hit={hit} key={hit.phrase.id} setActivePhrase={setActivePhrase} />;
          })}
      </ul>
    </div>
  );
};
export default HitsList;
