import { ToastContainer } from "react-toastify";
import GlobalAppStateProvider from "./GlobalAppStateProvider";
import LocaleProvider from "./LocaleProvider";
import PreviewProvider from "./PreviewProvider";
import TentipiConfigProvider from "./TentipiConfigProvider";
import InRiverAPIProvider from "./InRiverAPIProvider";

const OuterContextsProvider: React.FC = ({ children }) => {
  return (
    <GlobalAppStateProvider>
      <TentipiConfigProvider>
        <InRiverAPIProvider>
          <LocaleProvider>
            <PreviewProvider>
              <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              {children}
            </PreviewProvider>
          </LocaleProvider>
        </InRiverAPIProvider>
      </TentipiConfigProvider>
    </GlobalAppStateProvider>
  );
};

export default OuterContextsProvider;
