import { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import InRiverAPI from "../../helpers/InRiverAPI";
import { InRiverField, FieldValue, CVL } from "../../types";
import { useEntity } from "./EntityProvider";
import { useInRiverAPIConfig } from "./InRiverAPIProvider";

interface FieldsContext {
  fields: InRiverField[];
  cvls: CVL[];
  isLoading: boolean;
  saveFields: (data: FieldValue[]) => Promise<any>;
}

export const fieldsContext = createContext<FieldsContext>({
  fields: [],
  cvls: [],
  isLoading: false,
  saveFields: async (data) => {}
});

export const useFields = () => {
  return useContext(fieldsContext);
};

const FieldsProvider: React.FC = ({ children }) => {
  const [fields, setFields] = useState<InRiverField[]>([]);
  const [cvls] = useState<CVL[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const inRiverAPIConfig = useInRiverAPIConfig();
  const Entity = useEntity();

  useEffect(() => {
    if (Entity.id && Entity.LMSId) {
      setIsLoading(true);
      const fetchEntityData = async () => {
        try {
          const res = await InRiverAPI.get(
            `/entities/${Entity.id}/summary/fields`,
            inRiverAPIConfig
          );

          setFields(res.data);
        } catch (err) {
          toast.error("Couldn't fetch InRiver data");
          console.log("Error fetching fields data: ", err);
        }
      };
      const entityPromise = fetchEntityData();
      entityPromise
        .then(() => {
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }
  }, [Entity.id, Entity.LMSId, inRiverAPIConfig]);

  const saveFields = (data: FieldValue[]) => {
    return InRiverAPI.put(`/entities/${Entity.id}/fieldValues`, data, inRiverAPIConfig);
  };

  const contextValue = { fields, isLoading, cvls, saveFields };

  return <fieldsContext.Provider value={contextValue}>{children}</fieldsContext.Provider>;
};

export default FieldsProvider;
